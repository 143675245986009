import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { RepresentativesState, RepresentativesAction } from './types';
import {
  REPRESENTATIVES_FETCH_INIT,
  REPRESENTATIVES_FETCH_ERROR,
  REPRESENTATIVES_FETCH_SUCCESS,
  REPRESENTATIVES_POST_INIT,
  REPRESENTATIVES_POST_ERROR,
  REPRESENTATIVES_POST_SUCCESS,
  REPRESENTATIVES_RESET,
} from './constants';

import {
  fetchRepresentatives,
  fetchRepresentativesSuccess,
  fetchRepresentativesError,
  postRepresentatives,
  postRepresentativesSuccess,
  postRepresentativesError,
} from './actions';

const initialState: RepresentativesState = {
  representatives: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const representativesReducer: LoopReducer<
  RepresentativesState,
  RepresentativesAction
> = (
  state = initialState,
  action: RepresentativesAction
): Loop<RepresentativesState> | RepresentativesState => {
  switch (action.type) {
    case REPRESENTATIVES_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchRepresentatives, {
          successActionCreator: fetchRepresentativesSuccess,
          failActionCreator: fetchRepresentativesError,
          args: [action.clientId],
        })
      );

    case REPRESENTATIVES_FETCH_ERROR:
      return {
        ...state,
        representatives: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case REPRESENTATIVES_FETCH_SUCCESS:
      return {
        ...state,
        representatives: action.representatives,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case REPRESENTATIVES_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postRepresentatives, {
          successActionCreator: postRepresentativesSuccess,
          failActionCreator: postRepresentativesError,
          args: [action.clientId, action.representatives],
        })
      );

    case REPRESENTATIVES_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case REPRESENTATIVES_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case REPRESENTATIVES_RESET:
      return initialState;

    default:
      return state;
  }
};
