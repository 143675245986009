import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { AgreementState, AgreementAction } from './types';
import {
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_INIT,
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_ERROR,
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_SUCCESS,
} from './constants';

import {
  fetchProfessionalInvestorAgreementFile,
  fetchProfessionalInvestorAgreementFileSuccess,
  fetchProfessionalInvestorAgreementFileError,
} from './actions';

const initialState: AgreementState = {
  professionalInvestorFile: null,
  professionalInvestorFileLoading: false,
  professionalInvestorFileFetchError: undefined,
  professionalInvestorFileFetchSuccess: undefined,
};

export const professionalReducer: LoopReducer<
  AgreementState,
  AgreementAction
> = (
  state = initialState,
  action: AgreementAction
): Loop<AgreementState> | AgreementState => {
  switch (action.type) {
    case AGREEMENT_PROFESSIONAL_INVESTOR_FILE_INIT:
      return loop(
        {
          ...state,
          professionalInvestorFile: null,
          professionalInvestorFileLoading: true,
          professionalInvestorFileFetchError: undefined,
          professionalInvestorFileFetchSuccess: undefined,
        },
        Cmd.run(fetchProfessionalInvestorAgreementFile, {
          successActionCreator: fetchProfessionalInvestorAgreementFileSuccess,
          failActionCreator: fetchProfessionalInvestorAgreementFileError,
        })
      );

    case AGREEMENT_PROFESSIONAL_INVESTOR_FILE_ERROR:
      return {
        ...state,
        professionalInvestorFile: null,
        professionalInvestorFileLoading: false,
        professionalInvestorFileFetchError: action.error,
        professionalInvestorFileFetchSuccess: undefined,
      };

    case AGREEMENT_PROFESSIONAL_INVESTOR_FILE_SUCCESS:
      return {
        ...state,
        professionalInvestorFile: action.payload,
        professionalInvestorFileLoading: false,
        professionalInvestorFileFetchError: undefined,
        professionalInvestorFileFetchSuccess: true,
      };

    default:
      return state;
  }
};
