/* eslint-disable no-async-promise-executor */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { RequestOptions } from './types';

import { STEPS_RESET } from '../../Redux/Steps/constants';
import { AUTH_RESET } from '../../Redux/Auth/constants';
import { CUSTOMER_RESET } from '../../Redux/Customer/constants';
import { errorToFailedRequest } from './helpers';

export default function <T = any>(requestOptions: RequestOptions): Promise<T> {
  return new Promise<T>((accept, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require, @typescript-eslint/no-var-requires
    const store = require('../../Redux/store').default;

    const isFormData = requestOptions.body instanceof FormData;
    const fetchOptions: RequestInit = {
      method: requestOptions.method,
      credentials: 'same-origin',
      headers: requestOptions.headers,
      body: isFormData
        ? (requestOptions.body as FormData)
        : JSON.stringify(requestOptions.body),
    };

    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }

    if (!isFormData) {
      fetchOptions.headers['Content-Type'] = 'application/json';
    }

    if (requestOptions.authenticate) {
      fetchOptions.headers = {
        ...fetchOptions.headers,
        Authorization: `Bearer ${store.getState().authState.auth.token}`,
      };
    }

    let { url } = requestOptions;

    if (url.indexOf('/') === 0) {
      url = url.substring(1);
    }

    url = `${requestOptions.baseUrl || process.env.API_URL}${
      requestOptions.service
    }/${url}`;

    fetchPolyfill(url, fetchOptions)
      .then((response: Response) => {
        const statusCodesOnlySuccess =
          response.status - (response.status % 200) === 200;
        if (!statusCodesOnlySuccess) {
          if (response.status === 401) {
            store.dispatch({ type: CUSTOMER_RESET });
            store.dispatch({ type: STEPS_RESET });
            store.dispatch({ type: AUTH_RESET });
          }

          errorToFailedRequest(response).then(reject);
        } else if (requestOptions.returnResponse) {
          // @ts-ignore
          accept(response);
        } else {
          const contentType = response.headers.get('Content-Type') || '';
          if (contentType.indexOf('application/json') === -1) {
            if (requestOptions.returnPDF) {
              // @ts-ignore
              accept(response);
            } else {
              // @ts-ignore
              accept(response.text());
            }
          } else {
            // @ts-ignore
            response
              .text()
              .then((value) => {
                // @ts-ignore
                if (value) {
                  accept(JSON.parse(value));
                }
                // @ts-ignore
                accept();
              })
              .catch((error) => {
                reject(error);
              });
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
