import { Icons } from '@terrainvest/react-components';

import * as fasIcons from './fas';
import * as farIcons from './far';
import * as falIcons from './fal';

const getIconsFromImport = (iconsImport) => Object.values(iconsImport);

export const setupIcons = (): void => {
  Icons.setup(
    ...getIconsFromImport(fasIcons),
    ...getIconsFromImport(farIcons),
    ...getIconsFromImport(falIcons)
  );
};
