import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import {
  PATRIMONY_FETCH_INIT,
  PATRIMONY_FETCH_SUCCESS,
  PATRIMONY_FETCH_ERROR,
  PATRIMONY_RESET,
  PATRIMONY_POST_INIT,
  PATRIMONY_POST_SUCCESS,
  PATRIMONY_POST_ERROR,
} from './constants';

import { PatrimonyState, PatrimonyAction } from './types';

import {
  fetchPatrimony,
  fetchPatrimonySuccess,
  fetchPatrimonyError,
  postPatrimony,
  postPatrimonySuccess,
  postPatrimonyError,
} from './actions';

const initialState: PatrimonyState = {
  patrimony: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const patrimonyReducer: LoopReducer<PatrimonyState, PatrimonyAction> = (
  state = initialState,
  action: PatrimonyAction
): Loop<PatrimonyState> | PatrimonyState => {
  switch (action.type) {
    case PATRIMONY_FETCH_INIT:
      return loop(
        { ...state, fetchLoading: true },
        Cmd.run(fetchPatrimony, {
          successActionCreator: fetchPatrimonySuccess,
          failActionCreator: fetchPatrimonyError,
          args: [action.clientId],
        })
      );

    case PATRIMONY_FETCH_SUCCESS:
      return {
        ...state,
        patrimony: action.patrimony,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case PATRIMONY_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case PATRIMONY_POST_INIT:
      return loop(
        { ...state, postLoading: true },
        Cmd.run(postPatrimony, {
          successActionCreator: postPatrimonySuccess,
          failActionCreator: postPatrimonyError,
          args: [action.clientId, action.patrimony],
        })
      );

    case PATRIMONY_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case PATRIMONY_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case PATRIMONY_RESET:
      return initialState;

    default:
      return state;
  }
};
