export const AUTH_RESET = 'AUTH_RESET';

export const AUTH_CODE_VALIDATION_INIT = 'AUTH_CODE_VALIDATION_INIT';
export const AUTH_CODE_VALIDATION_ERROR = 'AUTH_CODE_VALIDATION_ERROR';
export const AUTH_CODE_VALIDATION_SUCCESS = 'AUTH_CODE_VALIDATION_SUCCESS';
export const AUTH_CODE_VALIDATION_RESET = 'AUTH_CODE_VALIDATION_RESET';

export const AUTH_SEND_CODE_RESET = 'AUTH_SEND_CODE_RESET';

export const SET_USER_DOCUMENT = 'SET_USER_DOCUMENT';

export const AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT =
  'AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT';
export const AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR =
  'AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR';
export const AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS =
  'AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS';
export const AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_RESET =
  'AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_RESET';
