import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import {
  KnowledgeSourcesState,
  KnowledgeSourceAction,
  KnowledgeSourceOption,
} from './types';

import {
  KNOWLEDGE_SOURCES_FETCH_INIT,
  KNOWLEDGE_SOURCES_FETCH_ERROR,
  KNOWLEDGE_SOURCES_FETCH_SUCCESS,
  SELECT_KNOWLEDGE_SOURCES,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS,
} from './constants';

import {
  fetchKnowledgeSources,
  fetchKnowledgeSourcesSuccess,
  fetchKnowledgeSourcesError,
  fetchKnowledgeSourceOptions,
  fetchKnowledgeSourceOptionsSuccess,
  fetchKnowledgeSourceOptionsError,
} from './actions';

const initialState: KnowledgeSourcesState = {
  knowledgeSources: undefined,
  options: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  fetchOptionsLoading: false,
  fetchOptionsSuccess: false,
  fetchOptionsError: undefined,
};

export const knowledgeSourcesReducer: LoopReducer<
  KnowledgeSourcesState,
  KnowledgeSourceAction
> = (
  state = initialState,
  action: KnowledgeSourceAction
): Loop<KnowledgeSourcesState> | KnowledgeSourcesState => {
  switch (action.type) {
    case KNOWLEDGE_SOURCES_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchKnowledgeSources, {
          successActionCreator: fetchKnowledgeSourcesSuccess,
          failActionCreator: fetchKnowledgeSourcesError,
        })
      );

    case KNOWLEDGE_SOURCES_FETCH_ERROR:
      return {
        ...state,
        knowledgeSources: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case KNOWLEDGE_SOURCES_FETCH_SUCCESS:
      return {
        ...state,
        knowledgeSources: action.knowledgeSources,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case SELECT_KNOWLEDGE_SOURCES: {
      let options: KnowledgeSourceOption[] | undefined;
      if (state.knowledgeSources) {
        options = state.knowledgeSources.find(
          (knowledgeSource) => knowledgeSource.id === action.valueSelected
        )?.subItens;
      }

      return { ...state, options };
    }

    case KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchOptionsLoading: true,
        },
        Cmd.run(fetchKnowledgeSourceOptions, {
          successActionCreator: fetchKnowledgeSourceOptionsSuccess,
          failActionCreator: fetchKnowledgeSourceOptionsError,
          args: [action.knowledgeSourceId],
        })
      );

    case KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR:
      return {
        ...state,
        options: undefined,
        fetchOptionsLoading: false,
        fetchOptionsError: action.error,
        fetchOptionsSuccess: false,
      };

    case KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        options: action.options,
        fetchOptionsLoading: false,
        fetchOptionsError: undefined,
        fetchOptionsSuccess: true,
      };

    default:
      return state;
  }
};
