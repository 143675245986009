import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { AdministratorsState, AdministratorsAction } from './types';
import {
  ADMINISTRATORS_FETCH_INIT,
  ADMINISTRATORS_FETCH_ERROR,
  ADMINISTRATORS_FETCH_SUCCESS,
  ADMINISTRATORS_POST_INIT,
  ADMINISTRATORS_POST_ERROR,
  ADMINISTRATORS_POST_SUCCESS,
  ADMINISTRATORS_RESET,
} from './constants';

import {
  fetchAdministrators,
  fetchAdministratorsSuccess,
  fetchAdministratorsError,
  postAdministrators,
  postAdministratorsSuccess,
  postAdministratorsError,
} from './actions';

const initialState: AdministratorsState = {
  administrators: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const administratorsReducer: LoopReducer<
  AdministratorsState,
  AdministratorsAction
> = (
  state = initialState,
  action: AdministratorsAction
): Loop<AdministratorsState> | AdministratorsState => {
  switch (action.type) {
    case ADMINISTRATORS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchAdministrators, {
          successActionCreator: fetchAdministratorsSuccess,
          failActionCreator: fetchAdministratorsError,
          args: [action.clientId],
        })
      );

    case ADMINISTRATORS_FETCH_ERROR:
      return {
        ...state,
        administrators: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case ADMINISTRATORS_FETCH_SUCCESS:
      return {
        ...state,
        administrators: action.administrators,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case ADMINISTRATORS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postAdministrators, {
          successActionCreator: postAdministratorsSuccess,
          failActionCreator: postAdministratorsError,
          args: [action.clientId, action.administrators],
        })
      );

    case ADMINISTRATORS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case ADMINISTRATORS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case ADMINISTRATORS_RESET:
      return initialState;

    default:
      return state;
  }
};
