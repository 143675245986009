import Api from '../../Helpers/Api';
import {
  SIGNATURE_AND_TERMS_POST_INIT,
  SIGNATURE_AND_TERMS_POST_SUCCESS,
  SIGNATURE_AND_TERMS_POST_ERROR,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR,
  SIGNATURE_AND_TERMS_RESET,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR,
} from './constants';
import { postSignAgreementInit, ClientAgreementResponse } from '../Agreement';
import {
  SignatureAndTermsAction,
  CustomerWithQualifiedInvestorFlag,
} from './types';

export const fetchExistingAgreementInit = (
  clientId: string | number
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT,
  clientId,
});

export const fetchExistingAgreementSuccess = (
  existingAgreements: ClientAgreementResponse[]
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS,
  existingAgreements,
});

export const fetchExistingAgreementError = (
  error: Error
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR,
  error,
});

export const fetchExistingAgreement = async (
  clientId: string | number
): Promise<ClientAgreementResponse[]> => {
  return Api<ClientAgreementResponse[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/client-agreement/client/${clientId}`,
    service: 'terra.compliance.agreement',
  });
};

export const postSignatureAndTermsInit = (
  customer: CustomerWithQualifiedInvestorFlag,
  password: string,
  signature: string,
  contractExists: boolean,
  qualifiedInvestorAgreementExists: boolean,
  professionalInvestorAgreementExists: boolean,
  passwordExists: boolean,
  signatureExists: boolean,
  personType: unknown
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_INIT,
  customer,
  password,
  signature,
  contractExists,
  qualifiedInvestorAgreementExists,
  professionalInvestorAgreementExists,
  passwordExists,
  signatureExists,
  personType,
});

export const postSignatureAndTermsSuccess = (): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_SUCCESS,
});

export const postSignatureAndTermsError = (
  error: Error
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_ERROR,
  error,
});

export const postSignatureAndTerms = async (
  customer: CustomerWithQualifiedInvestorFlag,
  password: string,
  signature: string,
  contractExists: boolean,
  qualifiedInvestorAgreementExists: boolean,
  professionalInvestorAgreementExists: boolean,
  passwordExists: boolean,
  signatureExists: boolean,
  personType: unknown
): Promise<void> => {
  const {
    clientId,
    userId,
    qualifiedInvestor,
    professionalInvestor,
  } = customer;

  if (!passwordExists) {
    await Api({
      method: 'PUT',
      authenticate: true,
      url: '/api/customer/change-password',
      body: { NewPassword: password },
      service: 'terra.authentication',
    });
  }

  if (!signatureExists) {
    await Api({
      method: 'POST',
      authenticate: true,
      url: '/api/electronic-check/create',
      body: {
        UserId: userId,
        ElectronicCheck: signature,
      },
      service: 'terra.authentication',
    });
  }

  if (!contractExists) {
    await Api<ClientAgreementResponse>({
      method: 'GET',
      authenticate: true,
      url: `/api/client-agreement/client/${clientId}/type/1`,
      service: 'terra.compliance.agreement',
    });

    const AgreementId = personType === 'J' ? 68 : 67;
    // SEND INTERMEDIATION CONTRACT
    await postSignAgreementInit(AgreementId, clientId, signature);
    // SEND RPA CONTRACT
    await postSignAgreementInit(32, clientId, signature);
  }

  if (qualifiedInvestor && !qualifiedInvestorAgreementExists) {
    await postSignAgreementInit(33, clientId, signature);
  }

  if (professionalInvestor && !professionalInvestorAgreementExists) {
    await postSignAgreementInit(34, clientId, signature);
  }
};

export const signatureAndTermsReset = (): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_RESET,
});

export const fetchSignatureAndTermsExistingSignatureInit = (
  userId: string | number
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT,
  userId,
});

export const fetchSignatureAndTermsExistingSignatureSuccess = (
  exists: boolean
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS,
  exists,
});

export const fetchSignatureAndTermsExistingSignatureError = (
  error: Error
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR,
  error,
});

export const fetchSignatureAndTermsExistingSignature = (
  userId: string | number
): Promise<boolean> =>
  Api({
    method: 'GET',
    authenticate: true,
    url: `/api/electronic-check/verify/${userId}`,
    service: 'terra.authentication',
  }).then((response) => {
    return response.HasEletronicCheck;
  });

export const postSginatureAndTermsNewSignatureInit = (
  userId: string | number
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT,
  userId,
});

export const postSginatureAndTermsNewSignatureSuccess = (): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS,
});

export const postSginatureAndTermsNewSignatureError = (
  error: Error
): SignatureAndTermsAction => ({
  type: SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR,
  error,
});

export const postSginatureAndTermsNewSignature = (
  userId: string | number
): Promise<void> =>
  Api({
    method: 'POST',
    authenticate: true,
    url: '/api/electronic-check/reset',
    body: {
      UserId: userId,
    },
    service: 'terra.authentication',
  });

export const finalizeRegister = (clientId: number | undefined): Promise<void> =>
  Api({
    method: 'PATCH',
    authenticate: true,
    url: '/api/client/finalize',
    body: { Id: clientId },
    service: 'terra.client',
  });

export const verifyPassword = (
  password: string
): Promise<{ IsValidPassword: boolean }> =>
  Api({
    method: 'POST',
    authenticate: true,
    url: '/api/password/validate',
    body: {
      CurrentPassword: password,
    },
    service: 'terra.authentication',
  });

export const verifySignature = (
  userId: number,
  signature: string
): Promise<{ IsAuthenticated: boolean }> =>
  Api({
    method: 'POST',
    authenticate: true,
    url: `/api/electronic-check/authenticate`,
    body: {
      UserId: userId,
      Signature: signature,
    },
    service: 'terra.authentication',
  });
