import Api from '../../Helpers/Api';

import {
  REPRESENTATIVES_FETCH_INIT,
  REPRESENTATIVES_FETCH_SUCCESS,
  REPRESENTATIVES_FETCH_ERROR,
  REPRESENTATIVES_POST_INIT,
  REPRESENTATIVES_POST_SUCCESS,
  REPRESENTATIVES_POST_ERROR,
  REPRESENTATIVES_RESET,
} from './constants';
import { RepresentativesAction, Representative } from './types';
import { normalizeRepresentatives } from './normalizer';

export const fetchRepresentativesInit = (
  clientId: string | number
): RepresentativesAction => ({
  type: REPRESENTATIVES_FETCH_INIT,
  clientId,
});

export const fetchRepresentativesSuccess = (
  representatives: Representative[]
): RepresentativesAction => ({
  type: REPRESENTATIVES_FETCH_SUCCESS,
  representatives,
});

export const fetchRepresentativesError = (
  error: Error
): RepresentativesAction => ({
  type: REPRESENTATIVES_FETCH_ERROR,
  error,
});

export const fetchRepresentatives = (
  clientId: string | number
): Promise<Representative[]> => {
  return Api({
    method: 'GET',
    authenticate: true,
    url: `/api/representative/${clientId}`,
    service: 'terra.register',
  }).then(normalizeRepresentatives);
};

export const postRepresentativesInit = (
  clientId: string | number,
  representatives: Representative[]
): RepresentativesAction => ({
  type: REPRESENTATIVES_POST_INIT,
  clientId,
  representatives,
});

export const postRepresentativesSuccess = (): RepresentativesAction => ({
  type: REPRESENTATIVES_POST_SUCCESS,
});

export const postRepresentativesError = (
  error: Error
): RepresentativesAction => ({
  type: REPRESENTATIVES_POST_ERROR,
  error,
});

export const postRepresentatives = (
  clientId: string | number,
  representatives: Representative[]
): Promise<Representative[]> => {
  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/representative/`,
    body: {
      ClientId: clientId,
      Representatives: representatives.map((representative) => ({
        ...representative,
        Percentage:
          representative.Percentage && representative.Percentage.replace
            ? Number(representative.Percentage.replace(',', '.'))
            : representative.Percentage,
        ClientId: clientId,
      })),
    },
    service: 'terra.register',
  });
};

export const resetRepresentatives = (): RepresentativesAction => ({
  type: REPRESENTATIVES_RESET,
});
