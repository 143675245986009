import { LOGO_LOCATION_MAP } from './constants';

import { sanitizeString } from '../../Helpers/sanitizeString';
import { PartnerNormalized, PartnerResponse } from './types';

const normalizeLogosData = (
  partner: PartnerResponse
): { logoLogin; logoMenu; logoIcon } | undefined => {
  if (!partner?.files?.length) {
    return undefined;
  }

  const logoMenu = partner.files.find(
    (file) => String(file.location) === LOGO_LOCATION_MAP.MENU
  );

  const logoIcon = partner.files.find(
    (file) => String(file.location) === LOGO_LOCATION_MAP.ICON
  );

  const logoLogin = partner.files.find(
    (file) => String(file.location) === LOGO_LOCATION_MAP.LOGIN
  );

  return { logoLogin, logoMenu, logoIcon };
};

export const normalizePartner = (
  partner: PartnerResponse
): PartnerNormalized => {
  const styleContent = JSON.parse(partner.style.css);

  const logosData = normalizeLogosData(partner);

  return {
    id: partner.id,
    advisorId: partner.advisorId,
    officeId: partner.officeId,
    name: partner.name,
    cnpj: sanitizeString(partner.cnpj),
    isTerraPartner: partner.isTerraPartner,
    logos: {
      login: logosData?.logoLogin,
      menu: logosData?.logoMenu,
      icon: logosData?.logoIcon,
    },
    style: {
      id: partner?.style?.id,
      name: partner?.style?.name,
      ...styleContent,
    },
  } as PartnerNormalized;
};
