import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { MissingDocumentsState, MissingDocumentsAction } from './types';
import {
  MISSING_DOCUMENTS_FETCH_INIT,
  MISSING_DOCUMENTS_FETCH_SUCCESS,
  MISSING_DOCUMENTS_FETCH_ERROR,
  MISSING_DOCUMENTS_SET_INDEX,
  MISSING_DOCUMENTS_FINISH_INIT,
  MISSING_DOCUMENTS_FINISH_SUCCESS,
  MISSING_DOCUMENTS_FINISH_ERROR,
  MISSING_DOCUMENTS_FETCH_KEY_INIT,
  MISSING_DOCUMENTS_FETCH_KEY_SUCCESS,
  MISSING_DOCUMENTS_FETCH_KEY_ERROR,
} from './constants';
import {
  fetchMissingDocuments,
  fetchMissingDocumentsSuccess,
  fetchMissingDocumentsError,
  postMissingDocumentsFinish,
  postMissingDocumentsFinishSuccess,
  postMissingDocumentsFinishError,
  fetchMissingDocumentsKey,
  fetchMissingDocumentsKeySuccess,
  fetchMissingDocumentsKeyError,
} from './actions';

export const initialState: MissingDocumentsState = {
  documents: undefined,
  currentDocument: undefined,
  fetchLoading: false,
  fetchSuccess: undefined,
  fetchError: undefined,
  finishLoading: false,
  finishSuccess: undefined,
  finishError: undefined,
  fetchKeyLoading: false,
  fetchKeySuccess: undefined,
  fetchKeyError: undefined,
  missingDocumentsUrlKey: undefined,
};

export const missingDocumentsReducer: LoopReducer<
  MissingDocumentsState,
  MissingDocumentsAction
> = (
  state = initialState,
  action: MissingDocumentsAction
): Loop<MissingDocumentsState> | MissingDocumentsState => {
  switch (action.type) {
    case MISSING_DOCUMENTS_FETCH_INIT:
      return loop(
        { ...state, fetchLoading: true },
        Cmd.run(fetchMissingDocuments, {
          successActionCreator: fetchMissingDocumentsSuccess,
          failActionCreator: fetchMissingDocumentsError,
          args: [action.clientId],
        })
      );

    case MISSING_DOCUMENTS_FETCH_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        currentDocument: 0,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case MISSING_DOCUMENTS_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case MISSING_DOCUMENTS_FINISH_INIT:
      return loop(
        { ...state, finishLoading: true },
        Cmd.run(postMissingDocumentsFinish, {
          successActionCreator: postMissingDocumentsFinishSuccess,
          failActionCreator: postMissingDocumentsFinishError,
          args: [action.clientId],
        })
      );

    case MISSING_DOCUMENTS_FINISH_SUCCESS:
      return {
        ...state,
        finishLoading: false,
        finishError: undefined,
        finishSuccess: true,
      };

    case MISSING_DOCUMENTS_FINISH_ERROR:
      return {
        ...state,
        finishLoading: false,
        finishError: action.error,
        finishSuccess: false,
      };

    case MISSING_DOCUMENTS_SET_INDEX:
      return {
        ...state,
        currentDocument: action.index,
      };

    case MISSING_DOCUMENTS_FETCH_KEY_INIT:
      return loop(
        {
          ...state,
          fetchKeyLoading: true,
          fetchKeySuccess: undefined,
          fetchKeyError: undefined,
          missingDocumentsUrlKey: undefined,
        },
        Cmd.run(fetchMissingDocumentsKey, {
          successActionCreator: fetchMissingDocumentsKeySuccess,
          failActionCreator: fetchMissingDocumentsKeyError,
          args: [action.document],
        })
      );

    case MISSING_DOCUMENTS_FETCH_KEY_SUCCESS:
      return {
        ...state,
        missingDocumentsUrlKey: action.key,
        fetchKeyLoading: false,
        fetchKeyError: undefined,
        fetchKeySuccess: true,
      };

    case MISSING_DOCUMENTS_FETCH_KEY_ERROR:
      return {
        ...state,
        fetchKeyLoading: false,
        fetchKeyError: action.error,
        fetchKeySuccess: false,
      };

    default:
      return state;
  }
};
