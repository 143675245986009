export const MISSING_DOCUMENTS_FETCH_INIT = 'MISSING_DOCUMENTS_FETCH_INIT';
export const MISSING_DOCUMENTS_FETCH_SUCCESS =
  'MISSING_DOCUMENTS_FETCH_SUCCESS';
export const MISSING_DOCUMENTS_FETCH_ERROR = 'MISSING_DOCUMENTS_FETCH_ERROR';

export const MISSING_DOCUMENTS_FINISH_INIT = 'MISSING_DOCUMENTS_FINISH_INIT';
export const MISSING_DOCUMENTS_FINISH_SUCCESS =
  'MISSING_DOCUMENTS_FINISH_SUCCESS';
export const MISSING_DOCUMENTS_FINISH_ERROR = 'MISSING_DOCUMENTS_FINISH_ERROR';

export const MISSING_DOCUMENTS_SET_INDEX = 'MISSING_DOCUMENTS_SET_INDEX';

export const MISSING_DOCUMENTS_FETCH_KEY_INIT =
  'MISSING_DOCUMENTS_FETCH_KEY_INIT';
export const MISSING_DOCUMENTS_FETCH_KEY_SUCCESS =
  'MISSING_DOCUMENTS_FETCH_KEY_SUCCESS';
export const MISSING_DOCUMENTS_FETCH_KEY_ERROR =
  'MISSING_DOCUMENTS_FETCH_KEY_ERROR';
