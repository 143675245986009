import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { OrderIssuersState, OrderIssuersAction } from './types';
import {
  ORDER_ISSUERS_FETCH_INIT,
  ORDER_ISSUERS_FETCH_ERROR,
  ORDER_ISSUERS_FETCH_SUCCESS,
  ORDER_ISSUERS_POST_INIT,
  ORDER_ISSUERS_POST_ERROR,
  ORDER_ISSUERS_POST_SUCCESS,
  ORDER_ISSUERS_RESET,
} from './constants';

import {
  fetchOrderIssuers,
  fetchOrderIssuersSuccess,
  fetchOrderIssuersError,
  postOrderIssuers,
  postOrderIssuersSuccess,
  postOrderIssuersError,
} from './actions';

const initialState: OrderIssuersState = {
  orderIssuers: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const orderIssuersReducer: LoopReducer<
  OrderIssuersState,
  OrderIssuersAction
> = (
  state = initialState,
  action: OrderIssuersAction
): Loop<OrderIssuersState> | OrderIssuersState => {
  switch (action.type) {
    case ORDER_ISSUERS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchOrderIssuers, {
          successActionCreator: fetchOrderIssuersSuccess,
          failActionCreator: fetchOrderIssuersError,
          args: [action.clientId],
        })
      );

    case ORDER_ISSUERS_FETCH_ERROR:
      return {
        ...state,
        orderIssuers: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case ORDER_ISSUERS_FETCH_SUCCESS:
      return {
        ...state,
        orderIssuers: action.orderIssuers,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case ORDER_ISSUERS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postOrderIssuers, {
          successActionCreator: postOrderIssuersSuccess,
          failActionCreator: postOrderIssuersError,
          args: [action.clientId, action.orderIssuers],
        })
      );

    case ORDER_ISSUERS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case ORDER_ISSUERS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case ORDER_ISSUERS_RESET:
      return initialState;

    default:
      return state;
  }
};
