import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { AssetManagerStates, AssetManagersAction } from './types';
import {
  ASSET_MANAGERS_FETCH_INIT,
  ASSET_MANAGERS_FETCH_ERROR,
  ASSET_MANAGERS_FETCH_SUCCESS,
  ASSET_MANAGERS_POST_INIT,
  ASSET_MANAGERS_POST_ERROR,
  ASSET_MANAGERS_POST_SUCCESS,
  ASSET_MANAGERS_RESET,
  ASSET_MANAGERS_FETCH_STATES_INIT,
  ASSET_MANAGERS_FETCH_STATES_SUCCESS,
  ASSET_MANAGERS_FETCH_STATES_ERROR,
  ASSET_MANAGERS_FETCH_CITIES_INIT,
  ASSET_MANAGERS_FETCH_CITIES_SUCCESS,
  ASSET_MANAGERS_FETCH_CITIES_ERROR,
} from './constants';

import {
  fetchAssetManagers,
  fetchAssetManagersSuccess,
  fetchAssetManagersError,
  postAssetManagers,
  postAssetManagersSuccess,
  postAssetManagersError,
  fetchAssetManagerStates,
  fetchAssetManagerCities,
} from './actions';

const initialState: AssetManagerStates = {
  assetManagerCities: [],
  assetManagerStates: [],
  assetManagers: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const assetManagersReducer: LoopReducer<
  AssetManagerStates,
  AssetManagersAction
> = (
  state = initialState,
  action: AssetManagersAction
): Loop<AssetManagerStates> | AssetManagerStates => {
  switch (action.type) {
    case ASSET_MANAGERS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchAssetManagers, {
          successActionCreator: fetchAssetManagersSuccess,
          failActionCreator: fetchAssetManagersError,
          args: [action.clientId],
        })
      );

    case ASSET_MANAGERS_FETCH_ERROR:
      return {
        ...state,
        assetManagers: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case ASSET_MANAGERS_FETCH_SUCCESS:
      return {
        ...state,
        assetManagers: action.assetManagers,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case ASSET_MANAGERS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postAssetManagers, {
          successActionCreator: postAssetManagersSuccess,
          failActionCreator: postAssetManagersError,
          args: [action.clientId, action.assetManagers],
        })
      );

    case ASSET_MANAGERS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case ASSET_MANAGERS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case ASSET_MANAGERS_FETCH_STATES_INIT:
      // eslint-disable-next-line no-case-declarations
      const newassetManagerstatesInit = [...state.assetManagerStates];

      newassetManagerstatesInit[action.index] = {
        loading: true,
        success: false,
        error: undefined,
        states: undefined,
      };

      return loop(
        {
          ...state,
          assetManagerstates: newassetManagerstatesInit,
        },
        Cmd.run(fetchAssetManagerStates, {
          args: [action.index, Cmd.dispatch],
        })
      );

    case ASSET_MANAGERS_FETCH_STATES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAssetManagerStatesSuccess = [...state.assetManagerStates];

      newAssetManagerStatesSuccess[action.index] = {
        loading: false,
        success: true,
        error: undefined,
        states: action.states,
      };

      return {
        ...state,
        assetManagerStates: newAssetManagerStatesSuccess,
      };

    case ASSET_MANAGERS_FETCH_STATES_ERROR:
      // eslint-disable-next-line no-case-declarations
      const newassetManagerstatesError = [...state.assetManagerStates];

      newassetManagerstatesError[action.index] = {
        loading: false,
        success: false,
        error: action.error,
        states: [],
      };

      return {
        ...state,
        assetManagerStates: newassetManagerstatesError,
      };

    case ASSET_MANAGERS_FETCH_CITIES_INIT:
      // eslint-disable-next-line no-case-declarations
      const newAssetManagerCitiesInit = [...state.assetManagerCities];

      newAssetManagerCitiesInit[action.index] = {
        loading: true,
        success: false,
        error: undefined,
        cities: undefined,
      };

      return loop(
        {
          ...state,
          assetManagerCities: newAssetManagerCitiesInit,
        },
        Cmd.run(fetchAssetManagerCities, {
          args: [action.index, action.stateId, Cmd.dispatch],
        })
      );

    case ASSET_MANAGERS_FETCH_CITIES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAssetManagerCitiesSuccess = [...state.assetManagerCities];

      newAssetManagerCitiesSuccess[action.index] = {
        loading: false,
        success: true,
        error: undefined,
        cities: action.cities,
      };

      return {
        ...state,
        assetManagerCities: newAssetManagerCitiesSuccess,
      };

    case ASSET_MANAGERS_FETCH_CITIES_ERROR:
      // eslint-disable-next-line no-case-declarations
      const newAssetManagerCitiesError = [...state.assetManagerCities];

      newAssetManagerCitiesError[action.index] = {
        loading: false,
        success: false,
        error: action.error,
        cities: [],
      };

      return {
        ...state,
        assetManagerCities: newAssetManagerCitiesError,
      };

    case ASSET_MANAGERS_RESET:
      return initialState;

    default:
      return state;
  }
};
