import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const StyledSpinner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;

  border: 5px solid;
  border-color: ${(props) => props.theme.global.colors.primary};
  border-left-color: ${(props) => props.theme.global.colors.primary};

  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
  margin: 6rem auto;

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
