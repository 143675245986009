import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { ComplementaryDataState, ComplementaryDataAction } from './types';
import {
  COMPLEMENTARY_DATA_FETCH_INIT,
  COMPLEMENTARY_DATA_FETCH_ERROR,
  COMPLEMENTARY_DATA_FETCH_SUCCESS,
  COMPLEMENTARY_DATA_POST_INIT,
  COMPLEMENTARY_DATA_POST_ERROR,
  COMPLEMENTARY_DATA_POST_SUCCESS,
  COMPLEMENTARY_DATA_RESET,
} from './constants';

import {
  fetchComplementaryData,
  fetchComplementaryDataSuccess,
  fetchComplementaryDataError,
  postComplementaryData,
  postComplementaryDataSuccess,
  postComplementaryDataError,
} from './actions';

const initialState: ComplementaryDataState = {
  complementaryData: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const complementaryDataReducer: LoopReducer<
  ComplementaryDataState,
  ComplementaryDataAction
> = (
  state = initialState,
  action: ComplementaryDataAction
): Loop<ComplementaryDataState> | ComplementaryDataState => {
  switch (action.type) {
    case COMPLEMENTARY_DATA_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchComplementaryData, {
          successActionCreator: fetchComplementaryDataSuccess,
          failActionCreator: fetchComplementaryDataError,
        })
      );

    case COMPLEMENTARY_DATA_FETCH_ERROR:
      return {
        ...state,
        complementaryData: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case COMPLEMENTARY_DATA_FETCH_SUCCESS:
      return {
        ...state,
        complementaryData: action.complementaryData,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case COMPLEMENTARY_DATA_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postComplementaryData, {
          successActionCreator: postComplementaryDataSuccess,
          failActionCreator: postComplementaryDataError,
          args: [action.complementaryData],
        })
      );

    case COMPLEMENTARY_DATA_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case COMPLEMENTARY_DATA_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case COMPLEMENTARY_DATA_RESET:
      return initialState;

    default:
      return state;
  }
};
