import decodeJwt from 'jwt-decode';

import { cleanDocument } from '../../Helpers/Document';
import Api from '../../Helpers/Api';
import {
  AuthenticateContinueRegisterResponse,
  AuthAction,
  IAuthData,
  FetchValidateTokenProps,
} from './types';

import {
  AUTH_CODE_VALIDATION_INIT,
  AUTH_CODE_VALIDATION_SUCCESS,
  AUTH_CODE_VALIDATION_ERROR,
  AUTH_CODE_VALIDATION_RESET,
  AUTH_SEND_CODE_RESET,
  AUTH_RESET,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR,
  SET_USER_DOCUMENT,
} from './constants';

export const postAuthValidateCustomerCodeInit = (
  document: string,
  code: string
): AuthAction => ({
  type: AUTH_CODE_VALIDATION_INIT,
  document: cleanDocument(document),
  code,
});

export const postAuthValidateCustomerCode = (
  document: string | undefined,
  code: string
): Promise<AuthenticateContinueRegisterResponse> => {
  return Api<AuthenticateContinueRegisterResponse>({
    url: '/api/customer/autenticate-continue-register',
    method: 'POST',
    service: 'terra.authentication',
    body: {
      Document: document,
      Token: code,
    },
    authenticate: false,
  }).then((response) => {
    return response;
  });
};

export const postAuthValidateCustomerCodeSuccess = (
  response: AuthenticateContinueRegisterResponse
): AuthAction => {
  const decodedToken = decodeJwt(response.AccessToken);

  return {
    type: AUTH_CODE_VALIDATION_SUCCESS,
    auth: {
      clientId: decodedToken.ClientId,
      userId: decodedToken.UserId,
      token: response.AccessToken,
    },
  };
};

export const postAuthValidateCustomerCodeError = (
  error: Error
): AuthAction => ({
  type: AUTH_CODE_VALIDATION_ERROR,
  error,
});

export const postAuthValidateCustomerCodeReset = (): AuthAction => ({
  type: AUTH_CODE_VALIDATION_RESET,
});

export const postAuthSendCode = (document: string): Promise<void> => {
  return Api({
    url: '/api/customer/token-continue-register',
    method: 'POST',
    service: 'terra.authentication',
    body: {
      Document: cleanDocument(document),
    },
    authenticate: false,
    returnResponse: true,
  });
};

export const postAuthSendCodeReset = (): AuthAction => ({
  type: AUTH_SEND_CODE_RESET,
});

export const validateMissingDocumentsTokenInit = (
  token: string
): AuthAction => ({
  type: AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT,
  token,
});

export const validateMissingDocumentsTokenSuccess = (
  auth: IAuthData
): AuthAction => ({
  type: AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS,
  auth,
});

export const validateMissingDocumentsTokenError = (
  error: Error
): AuthAction => ({
  type: AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR,
  error,
});

export const validateMissingDocumentsToken = async (
  token: string
): Promise<IAuthData> => {
  const response = await Api<Response>({
    url: '/api/customer/auth-upload',
    method: 'POST',
    service: 'terra.authentication',
    body: {
      Token: token,
    },
    authenticate: false,
    returnResponse: true,
  });

  if (response.status === 410) {
    throw new Error('Token Inválido');
  } else if (response.status !== 200) {
    throw new Error('Verifique sua conexão e tente novamente');
  }

  const { AccessToken: realToken } = await response.json();
  const { ClientId: clientId, UserId: userId } = decodeJwt(realToken);

  return {
    token: realToken,
    clientId,
    userId,
  };
};

export const authReset = (): AuthAction => ({
  type: AUTH_RESET,
});

export const setUserDocument = (document: string): AuthAction => ({
  type: SET_USER_DOCUMENT,
  document,
});

export const fetchNewPassword = (
  partnerId: number | undefined,
  document: string | undefined
): Promise<any> =>
  Api<Response>({
    url: '/api/password/request-new',
    method: 'PUT',
    service: 'terra.authentication',
    body: {
      partnerId,
      username: document,
    },
    authenticate: false,
    returnResponse: true,
  });

export const fetchValidateToken = ({
  username,
  token,
}: FetchValidateTokenProps): Promise<Response> =>
  Api<Response>({
    url: '/api/token/validate',
    method: 'POST',
    service: 'terra.authentication',
    body: {
      username,
      Token: token,
    },
    authenticate: false,
    returnResponse: true,
  });
