/* eslint-disable no-shadow */
import { WithDefaultActionHandling } from 'redux-loop';

// eslint-disable-next-line no-shadow
import {
  CUSTOMER_SET_TYPE,
  CUSTOMER_FETCH_INIT,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_UPDATE_INIT,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_SET_REFERENCED_ADVISOR_INIT,
  CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS,
  CUSTOMER_SET_REFERENCED_ADVISOR_ERROR,
  CUSTOMER_RESET,
  CUSTOMER_SET_REFERENCED_PARTNER,
  BEGIN_CUSTOMER,
  SET_CHANNEL_ID,
} from './constants';

import { FetchClientSuccessAction } from '../Client';

// eslint-disable-next-line no-shadow
export enum PersonType {
  J = 'J',
  F = 'F',
}

export interface CustomerState {
  customer: any;
  personType?: PersonType;
  channelId?: number;
  referencedPartner?: number;
  referencedAdvisor?: number;
  referencedAdvisorName?: string;
  referencedAdvisorNameLoading: boolean;
  referencedAdvisorNameSuccess: boolean;
  referencedAdvisorNameError?: Error;

  fetchLoading: boolean;
  fetchSuccess: boolean;
  fetchError?: Error;

  postUpdateLoading: boolean;
  postUpdateSuccess: boolean;
  postUpdateError?: Error;
}

export interface FetchAdvisorNameResponse {
  Id: number;
  Name: string;
}

export interface SetCustomerTypeAction {
  type: typeof CUSTOMER_SET_TYPE;
  personType: PersonType;
}

export interface SetCustomerReferencedAdvisorInitAction {
  type: typeof CUSTOMER_SET_REFERENCED_ADVISOR_INIT;
  advisorId: number;
}

export interface SetCustomerReferencedAdvisorSuccessAction {
  type: typeof CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS;
  advisorId: number;
  advisorName: string;
}

export interface SetCustomerReferencedAdvisorErrorAction {
  type: typeof CUSTOMER_SET_REFERENCED_ADVISOR_ERROR;
  error: Error;
}

export interface SetCustomerReferencedPartnerAction {
  type: typeof CUSTOMER_SET_REFERENCED_PARTNER;
  partnerId: number;
}

export interface FetchCustomerInitAction {
  type: typeof CUSTOMER_FETCH_INIT;
  clientId: string | number;
}

export interface FetchCustomerSuccessAction {
  type: typeof CUSTOMER_FETCH_SUCCESS;
  customer: any;
}

export interface FetchCustomerErrorAction {
  type: typeof CUSTOMER_FETCH_ERROR;
  error: Error;
}

export interface PostUpdateCustomerInitAction {
  type: typeof CUSTOMER_UPDATE_INIT;
  clientId: string | number;
  values: any;
}

export interface PostUpdateCustomerSuccessAction {
  type: typeof CUSTOMER_UPDATE_SUCCESS;
}

export interface PostUpdateCustomerErrorAction {
  type: typeof CUSTOMER_UPDATE_ERROR;
  error: Error;
}

export interface CustomerResetAction {
  type: typeof CUSTOMER_RESET;
  onlyClearStates: boolean;
}

export interface BeginCustomerAction {
  type: typeof BEGIN_CUSTOMER;
}

export interface SetChannelIdAction {
  type: typeof SET_CHANNEL_ID;
  value: number;
}

export type CustomerAction = WithDefaultActionHandling<
  | SetCustomerTypeAction
  | SetCustomerReferencedAdvisorInitAction
  | SetCustomerReferencedAdvisorSuccessAction
  | SetCustomerReferencedAdvisorErrorAction
  | SetCustomerReferencedPartnerAction
  | FetchCustomerInitAction
  | FetchCustomerSuccessAction
  | FetchCustomerErrorAction
  | PostUpdateCustomerInitAction
  | PostUpdateCustomerSuccessAction
  | PostUpdateCustomerErrorAction
  | CustomerResetAction
  | BeginCustomerAction
  | SetChannelIdAction
  | FetchClientSuccessAction
>;
