import { Cmd, loop, Loop, LoopReducer } from 'redux-loop';

import {
  PASSWORD_FETCH_EXISTING_INIT,
  PASSWORD_FETCH_EXISTING_SUCCESS,
  PASSWORD_FETCH_EXISTING_ERROR,
} from './constants';
import { PasswordState, PasswordAction } from './types';
import {
  fetchExistingPassword,
  fetchExistingPasswordSuccess,
  fetchExistingPasswordError,
} from './actions';

const initialState: PasswordState = {
  hasPassword: false,
  fetchExistingPasswordLoading: false,
  fetchExistingPasswordSuccess: false,
  fetchExistingPasswordError: undefined,
};

export const passwordReducer: LoopReducer<PasswordState, PasswordAction> = (
  state = initialState,
  action: PasswordAction
): Loop<PasswordState> | PasswordState => {
  switch (action.type) {
    case PASSWORD_FETCH_EXISTING_INIT:
      return loop(
        {
          ...state,
          fetchExistingPasswordLoading: true,
          fetchExistingPasswordSuccess: false,
          fetchExistingPasswordError: undefined,
        },
        Cmd.run(fetchExistingPassword, {
          successActionCreator: fetchExistingPasswordSuccess,
          failActionCreator: fetchExistingPasswordError,
        })
      );

    case PASSWORD_FETCH_EXISTING_SUCCESS:
      return {
        ...state,
        hasPassword: action.hasPassword,
        fetchExistingPasswordLoading: false,
        fetchExistingPasswordSuccess: true,
        fetchExistingPasswordError: undefined,
      };

    case PASSWORD_FETCH_EXISTING_ERROR:
      return {
        ...state,
        hasPassword: false,
        fetchExistingPasswordLoading: false,
        fetchExistingPasswordSuccess: false,
        fetchExistingPasswordError: action.error,
      };

    default:
      return state;
  }
};
