export const CUSTOMER_FETCH_INIT = 'CUSTOMER_FETCH_INIT';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_ERROR = 'CUSTOMER_FETCH_ERROR';

export const CUSTOMER_UPDATE_INIT = 'CUSTOMER_UPDATE_INIT';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';

export const CUSTOMER_SET_TYPE = 'CUSTOMER_SET_TYPE';
export const CUSTOMER_SET_REFERENCED_ADVISOR_INIT =
  'CUSTOMER_SET_REFERENCED_ADVISOR_INIT';
export const CUSTOMER_SET_REFERENCED_ADVISOR_ERROR =
  'CUSTOMER_SET_REFERENCED_ADVISOR_ERROR';
export const CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS =
  'CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS';

export const CUSTOMER_SET_REFERENCED_PARTNER =
  'CUSTOMER_SET_REFERENCED_PARTNER';

export const CUSTOMER_RESET = 'CUSTOMER_RESET';

export const BEGIN_CUSTOMER = 'BEGIN_CUSTOMER';

export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';

export const channelIds = {
  PF: 1,
  INSTITUTIONAL: 3,
  PJ: 5,
  OFFER_MANAGER: 6,
};
