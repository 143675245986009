import { Cmd, loop, Loop, LoopReducer } from 'redux-loop';

import {
  SIGNATURE_AND_TERMS_POST_INIT,
  SIGNATURE_AND_TERMS_POST_ERROR,
  SIGNATURE_AND_TERMS_POST_SUCCESS,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR,
  SIGNATURE_AND_TERMS_RESET,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS,
  SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS,
  SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR,
} from './constants';
import { SignatureAndTermsState, SignatureAndTermsAction } from './types';
import {
  fetchExistingAgreement,
  fetchExistingAgreementSuccess,
  fetchExistingAgreementError,
  postSignatureAndTerms,
  postSignatureAndTermsSuccess,
  postSignatureAndTermsError,
  fetchSignatureAndTermsExistingSignature,
  fetchSignatureAndTermsExistingSignatureSuccess,
  fetchSignatureAndTermsExistingSignatureError,
  postSginatureAndTermsNewSignature,
  postSginatureAndTermsNewSignatureSuccess,
  postSginatureAndTermsNewSignatureError,
} from './actions';

const initialState: SignatureAndTermsState = {
  contractExists: undefined,
  qualifiedInvestorContractExists: undefined,
  professionalInvestorContractExists: undefined,
  signatureExists: undefined,
  existingAgreements: [],
  fetchExistingAgreementLoading: false,
  fetchExistingAgreementSuccess: false,
  fetchExistingAgreementError: undefined,
  fetchExistingSignatureLoading: false,
  fetchExistingSignatureSuccess: false,
  fetchExistingSignatureError: undefined,
  postNewSignatureLoading: false,
  postNewSignatureSuccess: false,
  postNewSignatureError: undefined,
  postLoading: false,
  postSuccess: undefined,
  postError: undefined,
};

export const signatureAndTermsReducer: LoopReducer<
  SignatureAndTermsState,
  SignatureAndTermsAction
> = (
  state = initialState,
  action: SignatureAndTermsAction
): Loop<SignatureAndTermsState> | SignatureAndTermsState => {
  switch (action.type) {
    case SIGNATURE_AND_TERMS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
          postSuccess: undefined,
          postError: undefined,
        },
        Cmd.run(postSignatureAndTerms, {
          successActionCreator: postSignatureAndTermsSuccess,
          failActionCreator: postSignatureAndTermsError,
          args: [
            action.customer,
            action.password,
            action.signature,
            action.contractExists,
            action.qualifiedInvestorAgreementExists,
            action.professionalInvestorAgreementExists,
            action.passwordExists,
            action.signatureExists,
            action.personType,
          ],
        })
      );

    case SIGNATURE_AND_TERMS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postSuccess: undefined,
        postError: action.error,
      };

    case SIGNATURE_AND_TERMS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: undefined,
      };

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT:
      return loop(
        {
          ...state,
          fetchExistingAgreementLoading: false,
          fetchExistingAgreementSuccess: false,
          fetchExistingAgreementError: undefined,
        },
        Cmd.run(fetchExistingAgreement, {
          successActionCreator: fetchExistingAgreementSuccess,
          failActionCreator: fetchExistingAgreementError,
          args: [action.clientId],
        })
      );

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS:
      return {
        ...state,
        contractExists: action.existingAgreements.find(
          (agreement) => agreement.AgreementType === 1
        ),
        qualifiedInvestorContractExists: action.existingAgreements.find(
          (agreement) => agreement.AgreementId === 33
        ),
        professionalInvestorContractExists: action.existingAgreements.find(
          (agreement) => agreement.AgreementId === 34
        ),
        fetchExistingAgreementLoading: false,
        fetchExistingAgreementSuccess: true,
        fetchExistingAgreementError: undefined,
      };

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR:
      return {
        ...state,
        fetchExistingAgreementLoading: false,
        fetchExistingAgreementSuccess: false,
        fetchExistingAgreementError: action.error,
      };

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT:
      return loop(
        {
          ...state,
          fetchExistingSignatureLoading: false,
          fetchExistingSignatureSuccess: false,
          fetchExistingSignatureError: undefined,
        },
        Cmd.run(fetchSignatureAndTermsExistingSignature, {
          successActionCreator: fetchSignatureAndTermsExistingSignatureSuccess,
          failActionCreator: fetchSignatureAndTermsExistingSignatureError,
          args: [action.userId],
        })
      );

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureExists: action.exists,
        fetchExistingSignatureLoading: false,
        fetchExistingSignatureSuccess: true,
        fetchExistingSignatureError: undefined,
      };

    case SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR:
      return {
        ...state,
        fetchExistingSignatureLoading: false,
        fetchExistingSignatureSuccess: false,
        fetchExistingSignatureError: action.error,
      };

    case SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT:
      return loop(
        {
          ...state,
          postNewSignatureLoading: false,
          postNewSignatureSuccess: false,
          postNewSignatureError: undefined,
        },
        Cmd.run(postSginatureAndTermsNewSignature, {
          successActionCreator: postSginatureAndTermsNewSignatureSuccess,
          failActionCreator: postSginatureAndTermsNewSignatureError,
          args: [action.userId],
        })
      );

    case SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS:
      return {
        ...state,
        postNewSignatureLoading: false,
        postNewSignatureSuccess: true,
        postNewSignatureError: undefined,
      };

    case SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR:
      return {
        ...state,
        postNewSignatureLoading: false,
        postNewSignatureSuccess: false,
        postNewSignatureError: action.error,
      };

    case SIGNATURE_AND_TERMS_RESET:
      return initialState;

    default:
      return state;
  }
};
