export const ASSET_MANAGERS_FETCH_INIT = 'ASSET_MANAGERS_FETCH_INIT';
export const ASSET_MANAGERS_FETCH_SUCCESS = 'ASSET_MANAGERS_FETCH_SUCCESS';
export const ASSET_MANAGERS_FETCH_ERROR = 'ASSET_MANAGERS_FETCH_ERROR';

export const ASSET_MANAGERS_POST_INIT = 'ASSET_MANAGERS_POST_INIT';
export const ASSET_MANAGERS_POST_SUCCESS = 'ASSET_MANAGERS_POST_SUCCESS';
export const ASSET_MANAGERS_POST_ERROR = 'ASSET_MANAGERS_POST_ERROR';

export const ASSET_MANAGERS_RESET = 'ASSET_MANAGERS_RESET';

export const ASSET_MANAGERS_FETCH_STATES_INIT =
  'ASSET_MANAGERS_FETCH_STATES_INIT';
export const ASSET_MANAGERS_FETCH_STATES_SUCCESS =
  'ASSET_MANAGERS_FETCH_STATES_SUCCESS';
export const ASSET_MANAGERS_FETCH_STATES_ERROR =
  'ASSET_MANAGERS_FETCH_STATES_ERROR';

export const ASSET_MANAGERS_FETCH_CITIES_INIT =
  'ASSET_MANAGERS_FETCH_CITIES_INIT';
export const ASSET_MANAGERS_FETCH_CITIES_SUCCESS =
  'ASSET_MANAGERS_FETCH_CITIES_SUCCESS';
export const ASSET_MANAGERS_FETCH_CITIES_ERROR =
  'ASSET_MANAGERS_FETCH_CITIES_ERROR';

export const ASSET_MANAGERS_REMOVE_STATES_AND_CITIES =
  'ASSET_MANAGERS_REMOVE_STATES_AND_CITIES';
