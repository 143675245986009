import { createActions } from './actions';
import { createReducer } from './reducer';
import { CommonPrefixes } from './constants';
import {
  normalizeConstitutions,
  normalizeBusinessActivities,
  normalizeBanks,
  normalizeAdvisors,
  normalizeOptions,
  normalizeFatca,
  normalizeRepresentativeCategories,
} from './normalizers';
import { ReduxCreator, CreatedRedux } from './types';

export const createRedux = ({
  prefix: commonPrefix,
  endpoint,
  service,
  normalize,
}: ReduxCreator): CreatedRedux => {
  const prefix = commonPrefix.toUpperCase();

  const actions = createActions({ prefix, endpoint, service, normalize });
  const reducer = createReducer({ prefix, actions });

  return {
    prefix,
    actions,
    reducer,
  };
};

export const Commons = {
  StatusRegistrations: createRedux({
    prefix: CommonPrefixes.StatusRegistrations,
    endpoint: `/api/common/status-registrations`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Cities: createRedux({
    prefix: CommonPrefixes.Cities,
    endpoint: `/api/common/cities/:param`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  NationalityCities: createRedux({
    prefix: CommonPrefixes.NationalityCities,
    endpoint: `/api/common/cities/:param`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Countries: createRedux({
    prefix: CommonPrefixes.Countries,
    endpoint: `/api/common/countries/foreign`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  States: createRedux({
    prefix: CommonPrefixes.States,
    endpoint: `/api/common/states/:param`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  NationalityStates: createRedux({
    prefix: CommonPrefixes.NationalityStates,
    endpoint: `/api/common/states/BRA`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Nationalities: createRedux({
    prefix: CommonPrefixes.Nationalities,
    endpoint: `/api/common/nationalities`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Occupations: createRedux({
    prefix: CommonPrefixes.Occupations,
    endpoint: `/api/common/occupations`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  MaritalStatus: createRedux({
    prefix: CommonPrefixes.MaritalStatus,
    endpoint: `/api/common/marital-status`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  DocumentTypes: createRedux({
    prefix: CommonPrefixes.DocumentTypes,
    endpoint: `/api/common/document-types`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  DocumentIssuerAgencies: createRedux({
    prefix: CommonPrefixes.DocumentIssuerAgencies,
    endpoint: `/api/common/document-issuer-agencies`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Genders: createRedux({
    prefix: CommonPrefixes.Genders,
    endpoint: `/api/common/genders`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Receipt: createRedux({
    prefix: CommonPrefixes.Receipt,
    endpoint: `/api/common/receipt/:param`,
    service: 'terra.register',
    normalize: normalizeOptions,
  }),
  Banks: createRedux({
    prefix: CommonPrefixes.Banks,
    endpoint: `/api/common/../bank`,
    service: 'terra.register',
    normalize: normalizeBanks,
  }),
  Advisors: createRedux({
    prefix: CommonPrefixes.Advisors,
    endpoint: `/api/common/../advisor`,
    service: 'terra.register',
    normalize: normalizeAdvisors,
  }),
  RepresentativeCategory: createRedux({
    prefix: CommonPrefixes.RepresentativeCategory,
    endpoint: `/api/common/../representative-category`,
    service: 'terra.register',
    normalize: normalizeRepresentativeCategories,
  }),
  Constitutions: createRedux({
    prefix: CommonPrefixes.Constitutions,
    endpoint: `/api/common/constitution-form`,
    service: 'terra.register',
    normalize: normalizeConstitutions,
  }),
  BusinessActivities: createRedux({
    prefix: CommonPrefixes.BusinessActivities,
    endpoint: `/api/common/activities/J`,
    service: 'terra.register',
    normalize: normalizeBusinessActivities,
  }),
  Fatca: createRedux({
    prefix: CommonPrefixes.Fatca,
    endpoint: '/api/common/fatca',
    service: 'terra.register',
    normalize: normalizeFatca,
  }),
};

export const combineReducers = () => {
  return Object.entries(Commons).reduce(
    (previous, entry: any[]) => ({
      ...previous,
      [entry[0]]: entry[1].reducer,
    }),
    {} as { [K in keyof typeof Commons]: CreatedRedux['reducer'] }
  );
};

//  as ReducersMapObject<typeof Commons, any>
// as ReducersMapObject<typeof Commons, Action<any>>
