import { AddressAction, FetchAddressResponse } from './types';
import {
  ADDRESS_FETCH_INIT,
  ADDRESS_FETCH_SUCCESS,
  ADDRESS_FETCH_ERROR,
  ADDRESS_POST_INIT,
  ADDRESS_POST_SUCCESS,
  ADDRESS_POST_ERROR,
  ADDRESS_RESET,
} from './constants';
import Api, { FailedRequest } from '../../Helpers/Api';

export const fetchAddressInit = (clientId: number | string): AddressAction => ({
  type: ADDRESS_FETCH_INIT,
  clientId,
});

export const fetchAddressSuccess = (
  address: FetchAddressResponse
): AddressAction => ({
  type: ADDRESS_FETCH_SUCCESS,
  address,
});

export const fetchAddressError = (error: Error): AddressAction => ({
  type: ADDRESS_FETCH_ERROR,
  error,
});

export const fetchAddress = (
  clientId: number | string
): Promise<FetchAddressResponse> => {
  return Api<FetchAddressResponse>({
    service: 'terra.register',
    url: `/api/address/${clientId}`,
    method: 'GET',
    authenticate: true,
  })
    .then((res) => {
      if (res) {
        const normalizedPostalCode = res.PostalCode.replace(/\D/g, '') || '';
        return { ...res, PostalCode: normalizedPostalCode };
      }

      return res;
    })
    .catch((e: FailedRequest) => {
      if (e.response.status === 404) {
        return {} as any;
      }

      throw e;
    });
};

export const postAddressInit = (
  clientId: string | number,
  address: FetchAddressResponse
): AddressAction => ({
  type: ADDRESS_POST_INIT,
  address,
  clientId,
});

export const postAddressSuccess = (): AddressAction => ({
  type: ADDRESS_POST_SUCCESS,
});

export const postAddressError = (error: Error): AddressAction => ({
  type: ADDRESS_POST_ERROR,
  error,
});

export const postAddress = (
  clientId: string | number,
  address: FetchAddressResponse
): Promise<void> => {
  return Api<void>({
    service: 'terra.register',
    url: `/api/address`,
    method: address.AddressId ? 'PUT' : 'POST',
    authenticate: true,
    body: { ...address, ClientId: clientId, CountryId: 'BRA' },
  });
};

export const resetAddress = (): AddressAction => ({
  type: ADDRESS_RESET,
});
