import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { PersonalDataState, PersonalDataAction } from './types';
import {
  PERSONAL_DATA_FETCH_INIT,
  PERSONAL_DATA_FETCH_ERROR,
  PERSONAL_DATA_FETCH_SUCCESS,
  PERSONAL_DATA_POST_INIT,
  PERSONAL_DATA_POST_ERROR,
  PERSONAL_DATA_POST_SUCCESS,
  PERSONAL_DATA_RESET,
} from './constants';

import {
  fetchPersonalData,
  fetchPersonalDataSuccess,
  fetchPersonalDataError,
  postPersonalData,
  postPersonalDataSuccess,
  postPersonalDataError,
} from './actions';

const initialState: PersonalDataState = {
  personalData: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const PersonalDataReducer: LoopReducer<
  PersonalDataState,
  PersonalDataAction
> = (
  state = initialState,
  action: PersonalDataAction
): Loop<PersonalDataState> | PersonalDataState => {
  switch (action.type) {
    case PERSONAL_DATA_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchPersonalData, {
          successActionCreator: fetchPersonalDataSuccess,
          failActionCreator: fetchPersonalDataError,
        })
      );

    case PERSONAL_DATA_FETCH_ERROR:
      return {
        ...state,
        personalData: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case PERSONAL_DATA_FETCH_SUCCESS:
      return {
        ...state,
        personalData: action.personalData,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case PERSONAL_DATA_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postPersonalData, {
          successActionCreator: postPersonalDataSuccess,
          failActionCreator: postPersonalDataError,
          args: [action.personalData],
        })
      );

    case PERSONAL_DATA_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case PERSONAL_DATA_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case PERSONAL_DATA_RESET:
      return initialState;

    default:
      return state;
  }
};
