import { Administrator } from './types';

export const normalizeAdministrators = (
  administrators: Administrator[]
): Administrator[] =>
  administrators.length > 0
    ? administrators.map((administrator) => ({
        ...administrator,
        DocumentType:
          administrator.CpfCnpj.trim().length === 11 ? 'CPF' : 'CNPJ',
      }))
    : [
        {
          CpfCnpj: '',
          Name: '',
          Rg: '',
          Email: '',
        },
      ];
