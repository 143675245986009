export const KNOWLEDGE_SOURCES_FETCH_INIT = 'KNOWLEDGE_SOURCES_FETCH_INIT';
export const KNOWLEDGE_SOURCES_FETCH_SUCCESS =
  'KNOWLEDGE_SOURCES_FETCH_SUCCESS';
export const KNOWLEDGE_SOURCES_FETCH_ERROR = 'KNOWLEDGE_SOURCES_FETCH_ERROR';

export const KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT =
  'KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT';
export const KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS =
  'KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS';
export const KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR =
  'KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR';

export const SELECT_KNOWLEDGE_SOURCES = 'SELECT_KNOWLEDGE_SOURCES';
