import Api from '../../Helpers/Api';

import { BanksAction, BankResponse } from './types';
import {
  BANKS_FETCH_INIT,
  BANKS_FETCH_SUCCESS,
  BANKS_FETCH_ERROR,
  BANKS_POST_INIT,
  BANKS_POST_SUCCESS,
  BANKS_POST_ERROR,
  BANKS_RESET,
} from './constants';

export const fetchBanksInit = (clientId: string | number): BanksAction => ({
  type: BANKS_FETCH_INIT,
  clientId,
});

export const fetchBanksSuccess = (banks: BankResponse[]): BanksAction => ({
  type: BANKS_FETCH_SUCCESS,
  banks,
});

export const fetchBanksError = (error: Error): BanksAction => ({
  type: BANKS_FETCH_ERROR,
  error,
});

export const fetchBanks = (
  clientId: string | number
): Promise<BankResponse[]> => {
  return Api<BankResponse[]>({
    url: `/api/bank/${clientId}`,
    method: 'GET',
    service: 'terra.register',
    authenticate: true,
  });
};

export const postBanksInit = (
  clientId: string | number,
  banks: BankResponse[]
): BanksAction => ({
  type: BANKS_POST_INIT,
  clientId,
  banks,
});

export const postBanksSuccess = (): BanksAction => ({
  type: BANKS_POST_SUCCESS,
});

export const postBanksError = (error: Error): BanksAction => ({
  type: BANKS_POST_ERROR,
  error,
});

export const postBanks = (
  clientId: string | number,
  banks: BankResponse[]
): Promise<void> => {
  return Api<void>({
    url: `/api/bank`,
    method: 'PATCH',
    service: 'terra.register',
    authenticate: true,
    body: {
      ClientId: clientId,
      BankAccounts: banks.map((bank) => ({
        ...bank,
        ClientId: clientId,
      })),
    },
  });
};

export const banksReset = (): BanksAction => ({
  type: BANKS_RESET,
});
