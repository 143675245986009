export const Actions = {
  PARTNER_FETCH_INIT: 'PARTNER_FETCH_INIT',
  PARTNER_FETCH_SUCCESS: 'PARTNER_FETCH_SUCCESS',
  PARTNER_FETCH_ERROR: 'PARTNER_FETCH_ERROR',
  SET_FIRST_CONTACT_URL: 'SET_FIRST_CONTACT_URL',
};

export const PARTNER_TERRA_CNPJ = '03751794000113';

// eslint-disable-next-line no-shadow
export enum LOGO_LOCATION_MAP {
  MENU = '1',
  ICON = '2',
  LOGIN = '3',
}
