import Api from '../../Helpers/Api';
import {
  SuitabilityQuestionResponse,
  SuitabilityAction,
  SuitabilityQuestion,
} from './types';
import {
  SUITABILITY_FETCH_INIT,
  SUITABILITY_FETCH_SUCCESS,
  SUITABILITY_FETCH_ERROR,
  SUITABILITY_POST_INIT,
  SUITABILITY_POST_SUCCESS,
  SUITABILITY_POST_ERROR,
  SUITABILITY_RESET,
} from './constants';

export const fetchSuitabilityInit = (
  clientId: string | number
): SuitabilityAction => ({
  type: SUITABILITY_FETCH_INIT,
  clientId,
});

export const fetchSuitabilitySuccess = ({
  suitabilityResponse,
  questions,
  questionCount,
  formQuestions,
}: any): SuitabilityAction => ({
  type: SUITABILITY_FETCH_SUCCESS,
  payload: suitabilityResponse,
  questions,
  questionCount,
  formQuestions,
});

export const fetchSuitabilityError = (error: Error): SuitabilityAction => ({
  type: SUITABILITY_FETCH_ERROR,
  error,
});

export const fetchSuitability = async (clientId: number | string) => {
  const suitabilityResponse = await Api<SuitabilityQuestionResponse>({
    url: `/api/profile/${clientId}`,
    method: 'GET',
    authenticate: true,
    service: 'terra.compliance.suitability',
  });

  let responseIndex = 0;
  const formQuestions: any = { Questions: [] };
  const existingSuitabilityData = {
    Id: suitabilityResponse.Id,
    ClientId: suitabilityResponse.ClientId,
  };

  const responseMapper = (response) =>
    response.Questions.forEach((question) => {
      existingSuitabilityData[`Questions[${responseIndex}][Id]`] = question.Id;

      if (question.Type === 1) {
        question.Alternatives.forEach((alternative, alternativeIndex) => {
          const hasAnswer = question.Answers?.find(
            (answer) => answer.Id.toString() === alternative.Id.toString()
          );

          existingSuitabilityData[
            `Questions[${responseIndex}][Answers][${alternativeIndex}]`
          ] = hasAnswer?.Id || undefined;
        });
      } else if (question.Type === 0) {
        question.Answers.forEach((answer, answerIndex) => {
          existingSuitabilityData[
            `Questions[${responseIndex}][Answers][${answerIndex}]`
          ] = answer.Id;
        });
      }

      if (question.Questions) {
        responseMapper(question);
      } else {
        responseIndex += 1;
      }
    });

  responseMapper(suitabilityResponse);

  let questionCount = 0;

  const questionMapper = (question: SuitabilityQuestion) => {
    if (!question.Questions) {
      questionCount += 1;
    }

    if (question.Answers?.length) {
      formQuestions.Questions.push({
        Id: question.Id,
        Answers: question.Answers.map((answer: any) => answer.Id),
      });
    }

    return {
      id: question.Id,
      description: question.Description,
      type: question.Type,
      sorting: question.QuestionNumber,
      alternatives: question.Alternatives.map((alternative) => ({
        id: alternative.Id,
        description: alternative.Description,
      })),
      questions: question.Questions?.map(questionMapper),
    };
  };

  const questions = suitabilityResponse.Questions.map(questionMapper);

  return { suitabilityResponse, questions, questionCount, formQuestions };
};

export const postSuitabilityInit = (
  values: any,
  userId: string | number,
  clientId: string | number,
  suitabilityId: string | number
): SuitabilityAction => ({
  type: SUITABILITY_POST_INIT,
  values,
  userId,
  clientId,
  suitabilityId,
});

export const postSuitabilitySuccess = (): SuitabilityAction => ({
  type: SUITABILITY_POST_SUCCESS,
});

export const postSuitabilityError = (error: Error): SuitabilityAction => ({
  type: SUITABILITY_POST_ERROR,
  error,
});

export const postSuitability = (
  serializedFormData: any,
  clientId: string | number,
  userId: string | number,
  suitabilityId: string | number
) => {
  const method = 'POST';
  const serializedFormDataAsJson = {
    ...serializedFormData,
    UserId: userId,
    ClientId: clientId,
    Id: suitabilityId,
  };

  return Api<SuitabilityQuestionResponse>({
    url: '/api/profile',
    method,
    authenticate: true,
    service: 'terra.compliance.suitability',
    body: serializedFormDataAsJson,
  });
};

export const suitabilityReset = (): SuitabilityAction => ({
  type: SUITABILITY_RESET,
});
