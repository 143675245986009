import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';
import { CommonState } from './types';

export const createReducer = ({ prefix, actions }) => {
  const initialState: CommonState = {
    data: null,
    loading: false,
    error: null,
  };

  const reducer: LoopReducer<CommonState, any> = (
    state = initialState,
    action
  ): Loop<CommonState> | CommonState => {
    switch (action.type) {
      case `COMMON_${prefix}_FETCH_INIT`:
        return loop(
          { ...state, loading: true },
          Cmd.run(actions.fetch, {
            successActionCreator: actions.fetchSuccess,
            failActionCreator: actions.fetchError,
            args: [action.param],
          })
        );
      case `COMMON_${prefix}_FETCH_SUCCESS`:
        return {
          ...state,
          data: action.data,
          error: null,
          loading: false,
        };
      case `COMMON_${prefix}_FETCH_ERROR`:
        return { ...state, error: action.error, loading: false };
      default:
        return state;
    }
  };

  return reducer;
};
