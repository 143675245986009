import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { CompanyDataState, CompanyDataAction } from './types';
import {
  COMPANY_DATA_FETCH_INIT,
  COMPANY_DATA_FETCH_ERROR,
  COMPANY_DATA_FETCH_SUCCESS,
  COMPANY_DATA_POST_INIT,
  COMPANY_DATA_POST_ERROR,
  COMPANY_DATA_POST_SUCCESS,
  COMPANY_DATA_RESET,
} from './constants';

import {
  fetchCompanyData,
  fetchCompanyDataSuccess,
  fetchCompanyDataError,
  postCompanyData,
  postCompanyDataSuccess,
  postCompanyDataError,
} from './actions';

const initialState: CompanyDataState = {
  companyData: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const CompanyDataReducer: LoopReducer<
  CompanyDataState,
  CompanyDataAction
> = (
  state = initialState,
  action: CompanyDataAction
): Loop<CompanyDataState> | CompanyDataState => {
  switch (action.type) {
    case COMPANY_DATA_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchCompanyData, {
          successActionCreator: fetchCompanyDataSuccess,
          failActionCreator: fetchCompanyDataError,
        })
      );

    case COMPANY_DATA_FETCH_ERROR:
      return {
        ...state,
        companyData: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case COMPANY_DATA_FETCH_SUCCESS:
      return {
        ...state,
        companyData: action.companyData,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case COMPANY_DATA_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postCompanyData, {
          successActionCreator: postCompanyDataSuccess,
          failActionCreator: postCompanyDataError,
          args: [action.companyData],
        })
      );

    case COMPANY_DATA_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case COMPANY_DATA_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case COMPANY_DATA_RESET:
      return initialState;

    default:
      return state;
  }
};
