import { ClientAction, FetchClientResponse } from './types';
import {
  CLIENT_FETCH_INIT,
  CLIENT_FETCH_SUCCESS,
  CLIENT_FETCH_ERROR,
  CLIENT_POST_INIT,
  CLIENT_POST_SUCCESS,
  CLIENT_POST_ERROR,
  CLIENT_RESET,
} from './constants';

import { normalizeClient } from './normalizer';

import Api from '../../Helpers/Api';

export const fetchClientInit = (): ClientAction => ({
  type: CLIENT_FETCH_INIT,
});

export const fetchClientSuccess = (
  client: FetchClientResponse
): ClientAction => ({
  type: CLIENT_FETCH_SUCCESS,
  client,
  channelId: client.ChannelId,
});

export const fetchClientError = (error: Error): ClientAction => ({
  type: CLIENT_FETCH_ERROR,
  error,
});

export const fetchClient = (): Promise<FetchClientResponse> => {
  return Api<FetchClientResponse>({
    service: 'terra.register',
    url: `/api/client/basic-data`,
    method: 'GET',
    authenticate: true,
  }).then(normalizeClient);
};

export const postClientInit = (client: FetchClientResponse): ClientAction => ({
  type: CLIENT_POST_INIT,
  client,
});

export const postClientSuccess = (): ClientAction => ({
  type: CLIENT_POST_SUCCESS,
});

export const postClientError = (error: Error): ClientAction => ({
  type: CLIENT_POST_ERROR,
  error,
});

export const postClient = (client: FetchClientResponse): Promise<void> => {
  return Api<void>({
    service: 'terra.register',
    url: `/api/client/basic-data`,
    method: 'PATCH',
    authenticate: true,
    body: {
      ...client,
    },
  });
};

export const resetClient = (): ClientAction => ({
  type: CLIENT_RESET,
});
