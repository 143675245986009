type JsonLikeObject<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};

export interface RequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  baseUrl?: string;
  url: string;
  authenticate: boolean;
  body?: JsonLikeObject<any> | FormData;
  headers?: Headers;
  service: string;
  returnResponse?: boolean;
  returnPDF?: boolean;
}

export class FailedRequest extends Error {
  public response: Response;

  constructor(message: string, response: Response) {
    super(message);

    this.response = response;
  }
}
