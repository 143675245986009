import Api from '../../Helpers/Api';

import {
  CONTROLLERS_FETCH_INIT,
  CONTROLLERS_FETCH_SUCCESS,
  CONTROLLERS_FETCH_ERROR,
  CONTROLLERS_POST_INIT,
  CONTROLLERS_POST_SUCCESS,
  CONTROLLERS_POST_ERROR,
  CONTROLLERS_RESET,
} from './constants';
import { ControllersAction, Controller } from './types';

export const fetchControllersInit = (
  clientId: string | number
): ControllersAction => ({
  type: CONTROLLERS_FETCH_INIT,
  clientId,
});

export const fetchControllersSuccess = (
  controllers: Controller[]
): ControllersAction => ({
  type: CONTROLLERS_FETCH_SUCCESS,
  controllers,
});

export const fetchControllersError = (error: Error): ControllersAction => ({
  type: CONTROLLERS_FETCH_ERROR,
  error,
});

export const fetchControllers = (
  clientId: string | number
): Promise<Controller[]> => {
  return Api<Controller[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/controllers/${clientId}`,
    service: 'terra.register',
  });
};

export const postControllersInit = (
  clientId: string | number,
  controllers: Controller[]
): ControllersAction => ({
  type: CONTROLLERS_POST_INIT,
  clientId,
  controllers,
});

export const postControllersSuccess = (): ControllersAction => ({
  type: CONTROLLERS_POST_SUCCESS,
});

export const postControllersError = (error: Error): ControllersAction => ({
  type: CONTROLLERS_POST_ERROR,
  error,
});

export const postControllers = (
  clientId: string | number,
  controllers: Controller[]
): Promise<Controller[]> => {
  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/controllers/`,
    body: {
      ClientId: clientId,
      controllers: controllers.map((controller) => ({
        ...controller,
        Percentage:
          controller.Percentage && controller.Percentage.replace
            ? Number(controller.Percentage.replace(',', '.'))
            : controller.Percentage,
        ClientId: clientId,
      })),
    },
    service: 'terra.register',
  });
};

export const resetControllers = (): ControllersAction => ({
  type: CONTROLLERS_RESET,
});
