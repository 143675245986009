import { PersonalDataAction, FetchPersonalDataResponse } from './types';
import {
  PERSONAL_DATA_FETCH_INIT,
  PERSONAL_DATA_FETCH_SUCCESS,
  PERSONAL_DATA_FETCH_ERROR,
  PERSONAL_DATA_POST_INIT,
  PERSONAL_DATA_POST_SUCCESS,
  PERSONAL_DATA_POST_ERROR,
  PERSONAL_DATA_RESET,
} from './constants';
import Api from '../../Helpers/Api';
import { normalizePersonalData } from './normalizer';

export const fetchPersonalDataInit = (): PersonalDataAction => ({
  type: PERSONAL_DATA_FETCH_INIT,
});

export const fetchPersonalDataSuccess = (
  personalData: FetchPersonalDataResponse
): PersonalDataAction => ({
  type: PERSONAL_DATA_FETCH_SUCCESS,
  personalData,
});

export const fetchPersonalDataError = (error: Error): PersonalDataAction => ({
  type: PERSONAL_DATA_FETCH_ERROR,
  error,
});

export const fetchPersonalData = (): Promise<FetchPersonalDataResponse> => {
  return Api<FetchPersonalDataResponse>({
    service: 'terra.client',
    url: `/api/natural-person/personal-data`,
    method: 'GET',
    authenticate: true,
  }).then(normalizePersonalData);
};

export const postPersonalDataInit = (
  personalData: FetchPersonalDataResponse
): PersonalDataAction => ({
  type: PERSONAL_DATA_POST_INIT,
  personalData,
});

export const postPersonalDataSuccess = (): PersonalDataAction => ({
  type: PERSONAL_DATA_POST_SUCCESS,
});

export const postPersonalDataError = (error: Error): PersonalDataAction => ({
  type: PERSONAL_DATA_POST_ERROR,
  error,
});

export const postPersonalData = (
  personalData: FetchPersonalDataResponse
): Promise<void> => {
  return Api<void>({
    service: 'terra.client',
    url: `/api/natural-person/personal-data`,
    method: 'PUT',
    authenticate: true,
    body: {
      ...personalData,
    },
  });
};

export const resetPersonalData = (): PersonalDataAction => ({
  type: PERSONAL_DATA_RESET,
});
