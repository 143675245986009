import { FetchCompanyDataResponse } from './types';

export const normalizeCompanyData = (
  companyData: FetchCompanyDataResponse
): FetchCompanyDataResponse => ({
  ...companyData,
  Activity: companyData.Activity || null,
  ConstitutionFormat: companyData.ConstitutionFormat || null,
  StatusFatcaId: companyData.StatusFatcaId || null,
});
