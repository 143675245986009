export const sanitizeString = (value: string): string => {
  // https://metring.com.br/javascript-substituir-caracteres-especiais

  return value
    ? value
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
        .toLowerCase()
    : value;
};
