import { Cmd, loop, Loop, LoopReducer } from 'redux-loop';

import {
  CUSTOMER_SET_TYPE,
  CUSTOMER_FETCH_INIT,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_UPDATE_INIT,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_RESET,
  CUSTOMER_SET_REFERENCED_PARTNER,
  CUSTOMER_SET_REFERENCED_ADVISOR_INIT,
  CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS,
  CUSTOMER_SET_REFERENCED_ADVISOR_ERROR,
  BEGIN_CUSTOMER,
  SET_CHANNEL_ID,
} from './constants';
import { CLIENT_FETCH_SUCCESS } from '../Client';
import { CustomerState, CustomerAction } from './types';
import {
  fetchCustomer,
  fetchCustomerSuccess,
  fetchCustomerError,
  postUpdateCustomer,
  postUpdateCustomerSuccess,
  postUpdateCustomerError,
  setCustomerReferencedAdvisor,
  setCustomerReferencedAdvisorSuccess,
  setCustomerReferencedAdvisorError,
} from './actions';

const initialState: CustomerState = {
  customer: {},
  personType: undefined,
  channelId: undefined,
  referencedPartner: undefined,
  referencedAdvisor: undefined,
  referencedAdvisorNameLoading: false,
  referencedAdvisorNameSuccess: false,
  referencedAdvisorNameError: undefined,

  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,

  postUpdateLoading: false,
  postUpdateSuccess: false,
  postUpdateError: undefined,
};

export const customerReducer: LoopReducer<CustomerState, CustomerAction> = (
  state = initialState,
  action: CustomerAction
): Loop<CustomerState> | CustomerState => {
  switch (action.type) {
    case CUSTOMER_SET_TYPE:
      return {
        ...state,
        personType: action.personType,
      };

    case CUSTOMER_SET_REFERENCED_ADVISOR_INIT:
      return loop(
        {
          ...state,
          referencedAdvisor: undefined,
          referencedAdvisorNameLoading: true,
          referencedAdvisorNameSuccess: false,
          referencedAdvisorNameError: undefined,
        },
        Cmd.run(setCustomerReferencedAdvisor, {
          successActionCreator: setCustomerReferencedAdvisorSuccess,
          failActionCreator: setCustomerReferencedAdvisorError,
          args: [action.advisorId],
        })
      );

    case CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS:
      return {
        ...state,
        referencedAdvisor: action.advisorId,
        referencedAdvisorName: action.advisorName,
        referencedAdvisorNameLoading: false,
        referencedAdvisorNameSuccess: true,
        referencedAdvisorNameError: undefined,
      };

    case CUSTOMER_SET_REFERENCED_ADVISOR_ERROR:
      return {
        ...state,
        referencedAdvisorNameLoading: false,
        referencedAdvisorNameSuccess: false,
        referencedAdvisorNameError: action.error,
      };

    case CUSTOMER_SET_REFERENCED_PARTNER:
      return {
        ...state,
        referencedPartner: action.partnerId,
      };

    case CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        channelId: action.channelId,
      };

    case CUSTOMER_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
          fetchSuccess: false,
          fetchError: undefined,
        },
        Cmd.run(fetchCustomer, {
          successActionCreator: fetchCustomerSuccess,
          failActionCreator: fetchCustomerError,
          args: [action.clientId],
        })
      );

    case CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        customer: action.customer,
        fetchLoading: false,
        fetchSuccess: true,
        fetchError: undefined,
      };

    case CUSTOMER_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchSuccess: false,
        fetchError: action.error,
      };

    case CUSTOMER_UPDATE_INIT:
      return loop(
        {
          ...state,
          postUpdateLoading: true,
          postUpdateSuccess: false,
          postUpdateError: undefined,
        },
        Cmd.run(postUpdateCustomer, {
          successActionCreator: postUpdateCustomerSuccess,
          failActionCreator: postUpdateCustomerError,
          args: [action.values, action.clientId],
        })
      );

    case CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        postUpdateLoading: false,
        postUpdateSuccess: true,
        postUpdateError: undefined,
      };

    case CUSTOMER_UPDATE_ERROR:
      return {
        ...state,
        postUpdateLoading: false,
        postUpdateSuccess: false,
        postUpdateError: action.error,
      };

    case CUSTOMER_RESET:
      if (action.onlyClearStates) {
        return {
          ...initialState,
          customer: state.customer,
          personType: state.personType,
        };
      }

      return initialState;

    case BEGIN_CUSTOMER:
      return {
        ...initialState,
        channelId: state.channelId,
      };

    case SET_CHANNEL_ID:
      return {
        ...state,
        channelId: action.value,
      };

    default:
      return state;
  }
};
