export interface StepIndicatorProps {
  className?: string;
  countFrom?: number;
}

// False positive: see https://github.com/typescript-eslint/typescript-eslint/issues/325
// eslint-disable-next-line no-shadow
export enum StepLabels {
  PERSON_SELECT = 'Terra Investimentos - Seleção de Tipo de Conta',
  INDIVIDUAL_ENTITY = 'Terra Investimentos - Cadastro de Pessoa Física',
  LEGAL_ENTITY = 'Terra Investimentos - Cadastro de Pessoa Jurídica',
  ADDRESS = 'Endereço',
  DOCUMENTS = 'Identificação',
  DETAILS = 'Dados Pessoais',
  UPLOAD_DOCUMENTS = 'Envio de Documentos',
  FINANCIAL_DATA = 'Dados Financeiros',
  SUITABILITY = 'Perfil de Investidor',
  COMPANY_DATA = 'Dados da Empresa',
  TERMS = 'Termos',
  FINISHED = 'Conclusão',
}
