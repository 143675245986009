import { PersonType } from '../Redux/Customer';
import { StepLabels } from '../Components/StepIndicator/types';

const commonRoutes = {
  root: '/',
};

const missingDocumentsRoutes = {
  missingDocumentsUpload: '/documentos-pendentes-envio',
  missingDocumentsSuccess: '/documentos-pendentes-concluido',
  missingDocumentsAuth: '/documentos-pendentes/:authCode',
};

const offerManagementRoutes = {
  offerManagement: '/coordenacao-oferta',
};

export const signupRoutes = {
  ...commonRoutes,
  newSignup: '/novo-cadastro',
  continueSignup: '/continuar-cadastro',
  signupAddress: '/cadastrar/endereco',
  signupDetails: '/cadastrar/identificacao',
  signupUploadDocuments: '/cadastrar/envio-de-documentos',
  signupShowPdf: '/cadastrar/pdf',
  signupFinancialData: '/cadastrar/dados-financeiros',
  signupSuitability: '/cadastrar/suitability',
  signupTerms: '/cadastrar/termos-e-assinatura',
  signupFinished: '/cadastrar/concluido',
};

const routesPf = {
  ...signupRoutes,
  signupDocuments: '/cadastrar/documentos',
};

const routesPj = {
  ...signupRoutes,
};

const allRoutes = {
  ...commonRoutes,
  ...routesPj,
  ...routesPf,
  ...missingDocumentsRoutes,
  ...offerManagementRoutes,
};

export const signupRouteSteps = (personType: PersonType) => {
  if (personType === PersonType.F) {
    return [
      routesPf.signupAddress,
      routesPf.signupDocuments,
      routesPf.signupDetails,
      routesPf.signupUploadDocuments,
      routesPf.signupFinancialData,
      routesPf.signupSuitability,
      routesPf.signupTerms,
      routesPf.signupFinished,
    ];
  }

  return [
    routesPj.signupAddress,
    routesPj.signupDetails,
    routesPj.signupUploadDocuments,
    routesPj.signupFinancialData,
    routesPj.signupSuitability,
    routesPj.signupTerms,
    routesPj.signupFinished,
  ];
};

export const signupRouteStepsAndDescriptors = (
  personType: PersonType | undefined
) => {
  if (personType === PersonType.F) {
    return [
      { key: 1, label: StepLabels.ADDRESS, route: routesPf.signupAddress },
      { key: 2, label: StepLabels.DOCUMENTS, route: routesPf.signupDocuments },
      { key: 3, label: StepLabels.DETAILS, route: routesPf.signupDetails },
      {
        key: 4,
        label: StepLabels.UPLOAD_DOCUMENTS,
        route: routesPf.signupUploadDocuments,
      },
      {
        key: 5,
        label: StepLabels.FINANCIAL_DATA,
        route: routesPf.signupFinancialData,
      },
      {
        key: 6,
        label: StepLabels.SUITABILITY,
        route: routesPf.signupSuitability,
      },
      { key: 7, label: StepLabels.TERMS, route: routesPf.signupTerms },
      { key: 8, label: StepLabels.FINISHED, route: routesPf.signupFinished },
      {
        key: 9,
        label: StepLabels.UPLOAD_DOCUMENTS,
        route: routesPf.signupShowPdf,
      },
    ];
  }

  return [
    { key: 1, label: StepLabels.ADDRESS, route: routesPj.signupAddress },
    { key: 2, label: StepLabels.COMPANY_DATA, route: routesPj.signupDetails },
    {
      key: 3,
      label: StepLabels.UPLOAD_DOCUMENTS,
      route: routesPj.signupUploadDocuments,
    },
    {
      key: 4,
      label: StepLabels.FINANCIAL_DATA,
      route: routesPj.signupFinancialData,
    },
    {
      key: 5,
      label: StepLabels.SUITABILITY,
      route: routesPj.signupSuitability,
    },
    { key: 6, label: StepLabels.TERMS, route: routesPj.signupTerms },
    { key: 7, label: StepLabels.FINISHED, route: routesPj.signupFinished },
  ];
};

const validateParam = (param: string) => param.length > 0;

const validateRouteMatchWithParam = (
  appRoute: string,
  path: string
): boolean => {
  const appRouteSplitted = appRoute.split('/');
  const pathSplitted = path.split('/');

  if (appRouteSplitted.length !== pathSplitted.length) return false;

  const routeMatch = appRouteSplitted.every((routePiece, i) =>
    routePiece.includes(':')
      ? validateParam(pathSplitted[i])
      : routePiece === pathSplitted[i]
  );

  return routeMatch;
};

const validateRouteMatch = (appRoute: string, path: string): boolean => {
  if (appRoute === path) return true;

  if (appRoute.includes(':'))
    return validateRouteMatchWithParam(appRoute, path);

  return false;
};

export const validateCurrentRouteExistation = (): boolean => {
  const currentPathname = window.location.pathname;
  const allRoutesAsArray = Object.values(allRoutes);

  const hasMatch = allRoutesAsArray.some((route) =>
    validateRouteMatch(route, currentPathname)
  );

  return hasMatch;
};

export default allRoutes;
