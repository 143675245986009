export const REPRESENTATIVES_FETCH_INIT = 'REPRESENTATIVES_FETCH_INIT';
export const REPRESENTATIVES_FETCH_SUCCESS = 'REPRESENTATIVES_FETCH_SUCCESS';
export const REPRESENTATIVES_FETCH_ERROR = 'REPRESENTATIVES_FETCH_ERROR';

export const REPRESENTATIVES_POST_INIT = 'REPRESENTATIVES_POST_INIT';
export const REPRESENTATIVES_POST_SUCCESS = 'REPRESENTATIVES_POST_SUCCESS';
export const REPRESENTATIVES_POST_ERROR = 'REPRESENTATIVES_POST_ERROR';

export const REPRESENTATIVES_RESET = 'REPRESENTATIVES_RESET';

export const documentTypeOptions = [
  { id: 'CPF', value: 'CPF' },
  { id: 'CNPJ', value: 'CNPJ ' },
];
