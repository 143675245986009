import { Loop, LoopReducer, loop, Cmd } from 'redux-loop';
import { themeGenerator } from '@terrainvest/react-components';
import { Actions } from './constants';

import {
  PartnerState,
  PartnerAction,
  FetchPartnerSuccess,
  PartnerNormalized,
  SetFirstContactUrl,
} from './types';

import {
  fetchPartner,
  fetchPartnerSuccess,
  fetchPartnerError,
} from './actions';

const initialState: PartnerState = {
  partner: undefined,
  theme: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: false,
  firstContactUrl: '',
};

export const partnerReducer: LoopReducer<PartnerState, PartnerAction> = (
  state = initialState,
  action: PartnerAction
): Loop<PartnerState> | PartnerState => {
  switch (action.type) {
    case Actions.PARTNER_FETCH_INIT:
      return loop(
        { ...state, fetchLoading: true },
        Cmd.run(fetchPartner, {
          successActionCreator: fetchPartnerSuccess,
          failActionCreator: fetchPartnerError,
        })
      );

    case Actions.PARTNER_FETCH_SUCCESS:
      return {
        ...state,
        partner: (action as FetchPartnerSuccess).partner as PartnerNormalized,
        theme: themeGenerator(
          (action as FetchPartnerSuccess).partner.style.colors,
          (action as FetchPartnerSuccess).partner.style.typographyConfig
        ),
        fetchLoading: false,
        fetchError: false,
        fetchSuccess: true,
      };

    case Actions.PARTNER_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchError: true,
        fetchSuccess: false,
      };

    case Actions.SET_FIRST_CONTACT_URL:
      return {
        ...state,
        firstContactUrl: (action as SetFirstContactUrl).url,
      };

    default:
      return state;
  }
};
