import Api from '../../Helpers/Api/Api';

import {
  SIGNUP_CONTINUE_INIT,
  SIGNUP_CONTINUE_ERROR,
  SIGNUP_CONTINUE_SUCCESS,
  SIGNUP_CONTINUE_RESET,
  SIGNUP_CREATE_INIT,
  SIGNUP_CREATE_SUCCESS,
  SIGNUP_CREATE_ERROR,
} from './constants';

import {
  SignupAction,
  SignupParams,
  RegisterClientDocumentVerifyResponse,
  RegisterBasicDataResponse,
} from './types';

import { cleanDocument } from '../../Helpers/Document';
import { PersonType } from '../Customer';
import { convertRd } from '../../Helpers/RdStation';
import { PartnerNormalized } from '../Partner';

export const postCreateCustomerInit = (
  values: SignupParams,
  personType: PersonType,
  partner: PartnerNormalized | undefined,
  urlAtFirstContact: string,
  channelId: number | undefined,
  referencedAdvisor?: string | number
): SignupAction => {
  if (values.email !== values.emailConfirmation) {
    throw new Error('Verifique se o email e confirmação de email são iguais');
  }

  if (values.cellphone !== values.cellphoneConfirmation) {
    throw new Error(
      'Verifique se o seu número de celular e confirmação são iguais'
    );
  }

  if (!values.date) {
    throw new Error(
      `Data de ${
        personType === PersonType.J ? 'abertura' : 'nascimento'
      } é obrigatória`
    );
  }

  const cleanedCellphoneNumber = values.cellphone.replace(/[^\d]/g, '');
  const cleanedDocument = cleanDocument(values.document);

  const registerBody: any = {
    CpfCnpj: cleanedDocument,
    BirthDate: values.date,
    Email: values.email,
    MobilePhonePrefix: cleanedCellphoneNumber.substring(0, 2),
    MobilePhoneNumber: cleanedCellphoneNumber.substring(2),
    PersonType: personType,
    Name: values.name,
  };

  if (
    (values.knowledgeSourceId && values.knowledgeSourceOptionId) ||
    referencedAdvisor
  ) {
    registerBody.AdvisorId =
      values.knowledgeSourceOptionId || referencedAdvisor;
  }

  let payload = handlePartnerInfo(registerBody, partner);
  payload = handleAdditionalInfo(payload, urlAtFirstContact);
  payload = handleChannelId(payload, channelId);
  payload = handleKnowledgeId(payload, values.refererKnowledgeId);

  return {
    type: SIGNUP_CREATE_INIT,
    registerBody: payload,
    channelId,
    personType,
    partnerId: partner?.id,
  };
};

export const postCreateCustomer = async (
  registerBody,
  channelId,
  personType: PersonType
): Promise<boolean> => {
  const phoneNumber = `${registerBody.MobilePhonePrefix}${registerBody.MobilePhoneNumber}`;
  await Api<RegisterBasicDataResponse>({
    method: 'POST',
    authenticate: false,
    url: '/api/client/basic-data',
    body: registerBody,
    service: 'terra.register',
  });

  convertRd({
    identificador: 'lead-sql',
    email: registerBody.Email,
    nome: registerBody.Name,
    celular: phoneNumber,
    [personType === PersonType.J ? 'cnpj' : 'cpf']: registerBody.CpfCnpj,
  });

  sessionStorage.setItem('rdstation__email', registerBody.Email);

  return true;
};

export const postCreateCustomerSuccess = (created: boolean): SignupAction => ({
  type: SIGNUP_CREATE_SUCCESS,
  created,
});

export const postCreateCustomerError = (error: Error): SignupAction => ({
  type: SIGNUP_CREATE_ERROR,
  error,
});

export const fetchContinueSignupInit = (
  document: string,
  personType: PersonType,
  partnerId: number
): SignupAction => {
  const cleanedDocument = document && cleanDocument(document);
  const word = personType === PersonType.J ? 'CNPJ' : 'CPF';
  const documentLength = personType === PersonType.J ? 14 : 11;

  if (!document) {
    return {
      type: SIGNUP_CONTINUE_ERROR,
      error: new Error(`${word} é obrigatório`),
    };
  }

  if (cleanedDocument.length !== documentLength) {
    return {
      type: SIGNUP_CONTINUE_ERROR,
      error: new Error(`${word} inválido`),
    };
  }

  return {
    type: SIGNUP_CONTINUE_INIT,
    document: cleanedDocument,
    personType,
    partnerId,
  };
};

export const fetchContinueSignup = (
  document: string,
  partnerId: number
): Promise<RegisterClientDocumentVerifyResponse> => {
  return Api<RegisterClientDocumentVerifyResponse>({
    url: `/api/client/document/${document}/partner/${partnerId}/verify`,
    authenticate: false,
    method: 'GET',
    service: 'terra.client',
  });
};

export const fetchContinueSignupSuccess = (
  response: RegisterClientDocumentVerifyResponse
): SignupAction => {
  // Finished
  if (response.Exists === true && response.StatusRegistration === 4) {
    return {
      type: SIGNUP_CONTINUE_SUCCESS,
      exists: true,
      continue: false,
      analyzing: false,
      completed: true,
      waitingDocuments: false,
      differentPartner: false,
      differentPartnerUrl: '',
    };
  }

  // Different Partner
  if (response.IsCurrentPartner === false && response.PartnerRegistrationUrl) {
    return {
      type: SIGNUP_CONTINUE_SUCCESS,
      exists: true,
      continue: false,
      analyzing: false,
      completed: false,
      waitingDocuments: false,
      differentPartner: true,
      differentPartnerUrl: response.PartnerRegistrationUrl,
    };
  }

  // Continue
  if (response.Exists === true && response.StatusRegistration === 1) {
    return {
      type: SIGNUP_CONTINUE_SUCCESS,
      exists: true,
      continue: true,
      analyzing: false,
      completed: false,
      waitingDocuments: false,
      differentPartner: false,
      differentPartnerUrl: '',
    };
  }

  // Upload documents
  if (response.Exists === true && response.StatusRegistration === 3) {
    return {
      type: SIGNUP_CONTINUE_SUCCESS,
      exists: true,
      continue: false,
      analyzing: false,
      completed: false,
      waitingDocuments: true,
      differentPartner: false,
      differentPartnerUrl: '',
    };
  }

  // Exists but have any other status, will show up as analysis
  if (response.Exists === true) {
    return {
      type: SIGNUP_CONTINUE_SUCCESS,
      exists: true,
      continue: false,
      analyzing: true,
      completed: false,
      waitingDocuments: false,
      differentPartner: false,
      differentPartnerUrl: '',
    };
  }

  return {
    type: SIGNUP_CONTINUE_SUCCESS,
    exists: false,
    continue: false,
    analyzing: false,
    completed: false,
    waitingDocuments: false,
    differentPartner: false,
    differentPartnerUrl: '',
  };
};

export const fetchContinueSignupError = (error: Error): SignupAction => ({
  type: SIGNUP_CONTINUE_ERROR,
  error,
});

export const fetchContinueSignupReset = (): SignupAction => ({
  type: SIGNUP_CONTINUE_RESET,
});

const handleAdditionalInfo = (payload, urlAtFirstContact) => {
  const firstContactUrlFormated = urlAtFirstContact.replace('https://', '');
  return { ...payload, RegisterUrl: firstContactUrlFormated };
};

const handleChannelId = (payload, channelId) => {
  if (channelId) {
    return { ...payload, ChannelId: channelId };
  }
  return payload;
};

const handleKnowledgeId = (payload, refererKnowledgeId) => {
  if (refererKnowledgeId) {
    return { ...payload, KnowledgeId: refererKnowledgeId };
  }
  return payload;
};

const handlePartnerInfo = (registerBody, partner) => {
  const isPartnerOrThirdParty = !partner?.isTerraPartner;

  const newBody = setPartnerId(registerBody, partner);
  return isPartnerOrThirdParty ? setPartnerInfo(newBody, partner) : newBody;
};

const setPartnerInfo = (registerBody, partner) => {
  return {
    ...registerBody,
    AdvisorId: partner?.advisorId,
    OfficeId: partner?.officeId,
  };
};

const setPartnerId = (registerBody, partner) => {
  return {
    ...registerBody,
    PartnerId: partner?.id,
  };
};
