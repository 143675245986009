import React from 'react';
import ReactDOM from 'react-dom';
import { Loading as LoadingComponent } from '@terrainvest/react-components';

import { StyledContainer } from './styles';
import { LoadingProps } from './types';

const Loading: React.FC<LoadingProps> = ({ visible = false }) => {
  const [container] = React.useState(document.createElement('div'));

  React.useEffect(() => {
    const allowScroll = (): void => {
      try {
        document.body.removeChild(container);
        // eslint-disable-next-line no-empty
      } catch (_e) {}

      document.body.style.overflow = 'auto';
    };

    if (visible) {
      document.body.appendChild(container);
      document.body.style.overflow = 'hidden';
    } else {
      allowScroll();
    }

    return allowScroll;
  }, [container, visible]);

  if (visible) {
    return ReactDOM.createPortal(
      <StyledContainer>
        <LoadingComponent visible />
      </StyledContainer>,
      container
    );
  }

  return null;
};

export default Loading;
