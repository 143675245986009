import {
  KnowledgeSourcesResponse,
  KnowledgeSource,
  KnowledgeSourceOptionsResponse,
  KnowledgeSourceOption,
} from './types';

export const normalizeKnowledgeSources = (
  knowledgeSources: KnowledgeSourcesResponse[]
): KnowledgeSource[] => {
  return knowledgeSources.map((knowledgeSource) => ({
    id: knowledgeSource.Value,
    value: knowledgeSource.Description,
    subItens: normalizeKnowledgeSourceOptions(knowledgeSource.SubItens),
  }));
};

export const normalizeKnowledgeSourceOptions = (
  options?: KnowledgeSourceOptionsResponse[]
): KnowledgeSourceOption[] => {
  if (!options) {
    return [];
  }

  return options.map((option) => ({
    id: option.KnowledgeId,
    value: option.Description,
    knowledgeSourceId: option.Value,
  }));
};
