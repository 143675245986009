import React from 'react';

import { Button, H2, Text } from '@terrainvest/react-components';

import { ReactComponent as NoPartner } from '../../Assets/no-partner.svg';

import {
  StyledContainer,
  StyledContent,
  StyledFirstText,
  StyledInfoText,
  StyledLogo,
} from './styles';

const NoPartnerView: React.FC = () => (
  <StyledContainer>
    <StyledLogo />
    <StyledContent>
      <NoPartner />
      <StyledInfoText>
        <H2>Erro ao localizar o parceiro</H2>
        <StyledFirstText>
          Não conseguimos localizar o parceiro solicitado, verifique se o
          endereço está correto.
        </StyledFirstText>
        <Text>Para acessar o Site da Terra clique no botão abaixo</Text>
      </StyledInfoText>
    </StyledContent>
    <Button
      outline
      fullWidth
      onClick={() => {
        const anchor = document.createElement('a');
        anchor.href = process.env.SITE_URL || '';
        anchor.target = '_self';

        if (!(window as any).Cypress) {
          anchor.click();
        }
      }}
    >
      ACESSAR SITE TERRA INVESTIMENTOS
    </Button>
  </StyledContainer>
);

export default NoPartnerView;
