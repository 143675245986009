import { PersonType, CustomerAction, FetchAdvisorNameResponse } from './types';
import {
  CUSTOMER_SET_TYPE,
  CUSTOMER_FETCH_INIT,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_UPDATE_INIT,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_RESET,
  CUSTOMER_SET_REFERENCED_PARTNER,
  CUSTOMER_SET_REFERENCED_ADVISOR_INIT,
  CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS,
  CUSTOMER_SET_REFERENCED_ADVISOR_ERROR,
  BEGIN_CUSTOMER,
  SET_CHANNEL_ID,
} from './constants';
import Api from '../../Helpers/Api';

export const setCustomerType = (type: PersonType): CustomerAction => ({
  type: CUSTOMER_SET_TYPE,
  personType: type,
});

export const setCustomerReferencedAdvisorInit = (
  advisorId: number
): CustomerAction => ({
  type: CUSTOMER_SET_REFERENCED_ADVISOR_INIT,
  advisorId,
});

export const setCustomerReferencedAdvisorSuccess = (
  advisor: FetchAdvisorNameResponse
): CustomerAction => ({
  type: CUSTOMER_SET_REFERENCED_ADVISOR_SUCCESS,
  advisorId: advisor.Id,
  advisorName: advisor.Name,
});

export const setCustomerReferencedAdvisorError = (
  error: Error
): CustomerAction => ({
  type: CUSTOMER_SET_REFERENCED_ADVISOR_ERROR,
  error,
});

export const setCustomerReferencedAdvisor = (
  advisorId: number
): Promise<FetchAdvisorNameResponse> => {
  return Api<FetchAdvisorNameResponse>({
    url: `/api/advisor/${advisorId}/name`,
    method: 'GET',
    service: 'terra.register.aai',
    authenticate: false,
  });
};

export const setCustomerReferencedPartner = (
  partnerId: number
): CustomerAction => ({
  type: CUSTOMER_SET_REFERENCED_PARTNER,
  partnerId,
});

export const fetchCustomerInit = (
  clientId: string | number
): CustomerAction => ({
  type: CUSTOMER_FETCH_INIT,
  clientId,
});

export const fetchCustomer = (clientId: string | number): Promise<any> => {
  return Api({
    url: `/api/client/${clientId}`,
    method: 'GET',
    service: 'terra.register',
    authenticate: true,
  }).then((json) => json);
};

export const fetchCustomerSuccess = (customer: any): CustomerAction => ({
  type: CUSTOMER_FETCH_SUCCESS,
  customer,
});

export const fetchCustomerError = (error: Error): CustomerAction => ({
  type: CUSTOMER_FETCH_ERROR,
  error,
});

export const postUpdateCustomerInit = (
  values: any,
  clientId: string | number
): CustomerAction => ({
  type: CUSTOMER_UPDATE_INIT,
  clientId,
  values,
});

export const postUpdateCustomer = async (
  values: any,
  clientId: string | number
): Promise<void> => {
  const clientBody = values;
  const addressBody = { ...values.Address, ClientId: clientId };
  const patrimonyBody = { ...values.Patrimony, ClientId: clientId };

  if (!addressBody.CountryId) {
    addressBody.CountryId = 'BRA';
  }

  const banksBodies = (values.BankAccounts || []).map((bank) => ({
    ...bank,
    ClientId: clientId,
  }));

  const orderIssuersBodies = (clientBody.OrderIssuers || []).map(
    (orderIssuer) => ({
      ...orderIssuer,
      ClientId: clientId,
    })
  );

  const representativesBodies = (clientBody.Representatives || []).map(
    (representative) => ({
      ...representative,
      ClientId: clientId,
    })
  );

  await Api({
    url: '/api/client',
    method: 'PUT',
    service: 'terra.register',
    authenticate: true,
    body: clientBody,
  });

  await Api({
    url: '/api/address',
    method: parseInt(values.Address?.AddressId, 10) > 0 ? 'PUT' : 'POST',
    service: 'terra.register',
    authenticate: true,
    body: addressBody,
  });

  await Api({
    url: '/api/patrimony',
    method: 'POST',
    service: 'terra.register',
    authenticate: true,
    body: patrimonyBody,
  });

  await Api({
    url: '/api/bank',
    method: 'PATCH',
    service: 'terra.register',
    authenticate: true,
    body: { BankAccounts: banksBodies },
  });

  await Api({
    url: '/api/order-issuer',
    method: 'PATCH',
    service: 'terra.register',
    authenticate: true,
    body: { Issuers: orderIssuersBodies },
  });

  await Api({
    url: '/api/representative',
    method: 'PATCH',
    service: 'terra.register',
    authenticate: true,
    body: { Representatives: representativesBodies },
  });
};

export const postUpdateCustomerSuccess = (): CustomerAction => ({
  type: CUSTOMER_UPDATE_SUCCESS,
});

export const postUpdateCustomerError = (error: Error): CustomerAction => ({
  type: CUSTOMER_UPDATE_ERROR,
  error,
});

export const customerReset = (onlyClearStates = false): CustomerAction => ({
  type: CUSTOMER_RESET,
  onlyClearStates,
});

export const beginCustomer = (): CustomerAction => ({
  type: BEGIN_CUSTOMER,
});

export const setChannelId = (value: number): CustomerAction => ({
  type: SET_CHANNEL_ID,
  value,
});
