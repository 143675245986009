/* eslint-disable @typescript-eslint/naming-convention */
import cookies from 'browser-cookies';

export function convertRd(data) {
  if (process.env.NODE_ENV !== 'production') {
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }

  const c_utmz = cookies.get('__utmz'); // eslint-disable-line

  const payload = {
    token_rdstation: 'c5e11322d65dfa1ee15ac8fe43367532',
    c_utmz,
    ...data,
  };

  return fetch('https://www.rdstation.com.br/api/1.3/conversions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}
