import Api from '../../Helpers/Api';

export const createActions = ({
  prefix,
  endpoint,
  service,
  normalize = (data) => data,
}) => ({
  fetchInit: (param?) => ({
    type: `COMMON_${prefix}_FETCH_INIT`,
    param,
  }),
  fetch: (param) =>
    Api({
      url: endpoint.replace(':param', param),
      method: 'GET',
      authenticate: true,
      service,
    }).then(normalize),
  fetchSuccess: (data) => ({
    type: `COMMON_${prefix}_FETCH_SUCCESS`,
    data,
  }),
  fetchError: (error) => ({
    type: `COMMON_${prefix}_FETCH_ERROR`,
    error,
  }),
});

export const getAddress = (postalCode: string) =>
  Api({
    url: '/address',
    method: 'POST',
    body: {
      PostalCode: postalCode,
    },
    service: 'terra.register.searchaddress',
    authenticate: false,
  });
