import {
  KnowledgeSourceAction,
  KnowledgeSource,
  KnowledgeSourcesResponse,
  KnowledgeSourceOption,
  KnowledgeSourceOptionsResponse,
} from './types';

import {
  KNOWLEDGE_SOURCES_FETCH_INIT,
  KNOWLEDGE_SOURCES_FETCH_SUCCESS,
  KNOWLEDGE_SOURCES_FETCH_ERROR,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS,
  KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR,
  SELECT_KNOWLEDGE_SOURCES,
} from './constants';

import {
  normalizeKnowledgeSources,
  normalizeKnowledgeSourceOptions,
} from './normalizer';

import Api from '../../Helpers/Api';

export const fetchKnowledgeSourcesInit = (): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_FETCH_INIT,
});

export const fetchKnowledgeSourcesSuccess = (
  knowledgeSources: KnowledgeSource[]
): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_FETCH_SUCCESS,
  knowledgeSources,
});

export const fetchKnowledgeSourcesError = (
  error: Error
): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_FETCH_ERROR,
  error,
});

export const fetchKnowledgeSources = (): Promise<KnowledgeSource[]> => {
  return Api<KnowledgeSourcesResponse[]>({
    service: 'terra.register',
    url: '/api/common/v3/knowledge-sources',
    method: 'GET',
    authenticate: false,
  }).then(normalizeKnowledgeSources);
};

export const selectKnowledgeSources = (
  valueSelected: number
): KnowledgeSourceAction => ({
  type: SELECT_KNOWLEDGE_SOURCES,
  valueSelected,
});

export const fetchKnowledgeSourceOptionsInit = (
  knowledgeSourceId: string | number
): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_OPTIONS_FETCH_INIT,
  knowledgeSourceId,
});

export const fetchKnowledgeSourceOptionsSuccess = (
  options: KnowledgeSourceOption[]
): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_OPTIONS_FETCH_SUCCESS,
  options,
});

export const fetchKnowledgeSourceOptionsError = (
  error: Error
): KnowledgeSourceAction => ({
  type: KNOWLEDGE_SOURCES_OPTIONS_FETCH_ERROR,
  error,
});

export const fetchKnowledgeSourceOptions = (
  knowledgeSourceId: string | number
): Promise<KnowledgeSourceOption[]> => {
  return Api<Response>({
    service: 'terra.register',
    url: `/api/common/v2/${knowledgeSourceId}/knowledge-sources/`,
    method: 'GET',
    authenticate: false,
    returnResponse: true,
  })
    .then(async (response: Response) => {
      try {
        const json: KnowledgeSourceOptionsResponse[] = await response.json();
        return json;
      } catch (_e) {
        return undefined;
      }
    })
    .then(normalizeKnowledgeSourceOptions);
};
