import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import {
  AUTH_CODE_VALIDATION_INIT,
  AUTH_CODE_VALIDATION_SUCCESS,
  AUTH_CODE_VALIDATION_ERROR,
  AUTH_CODE_VALIDATION_RESET,
  AUTH_RESET,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS,
  AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR,
  SET_USER_DOCUMENT,
} from './constants';

import { AuthState, AuthAction } from './types';

import {
  postAuthValidateCustomerCode,
  postAuthValidateCustomerCodeSuccess,
  postAuthValidateCustomerCodeError,
  validateMissingDocumentsToken,
  validateMissingDocumentsTokenSuccess,
  validateMissingDocumentsTokenError,
} from './actions';

const initialState: AuthState = {
  auth: undefined,
  validateLoading: false,
  validateSuccess: false,
  validateError: undefined,
  validateMissingDocumentsTokenLoading: false,
  validateMissingDocumentsTokenSuccess: undefined,
  validateMissingDocumentsTokenError: undefined,
  userDocument: undefined,
};

export const authReducer: LoopReducer<AuthState, AuthAction> = (
  state = initialState,
  action: AuthAction
): Loop<AuthState> | AuthState => {
  switch (action.type) {
    case AUTH_CODE_VALIDATION_INIT:
      return loop(
        { ...state, validateLoading: true },
        Cmd.run(postAuthValidateCustomerCode, {
          successActionCreator: postAuthValidateCustomerCodeSuccess,
          failActionCreator: postAuthValidateCustomerCodeError,
          args: [action.document, action.code],
        })
      );

    case AUTH_CODE_VALIDATION_SUCCESS:
      return {
        ...state,
        auth: action.auth,
        validateLoading: false,
        validateError: undefined,
        validateSuccess: true,
      };

    case AUTH_CODE_VALIDATION_ERROR:
      return {
        ...state,
        validateLoading: false,
        validateError: action.error,
        validateSuccess: false,
      };

    case AUTH_CODE_VALIDATION_RESET:
      return {
        ...state,
        validateLoading: false,
        validateError: undefined,
        validateSuccess: false,
      };

    case AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_INIT:
      return loop(
        { ...state, validateMissingDocumentsTokenLoading: true },
        Cmd.run(validateMissingDocumentsToken, {
          successActionCreator: validateMissingDocumentsTokenSuccess,
          failActionCreator: validateMissingDocumentsTokenError,
          args: [action.token],
        })
      );

    case AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        auth: action.auth,
        validateMissingDocumentsTokenLoading: false,
        validateMissingDocumentsTokenError: undefined,
        validateMissingDocumentsTokenSuccess: true,
      };

    case AUTH_MISSING_DOCUMENTS_VALIDATE_TOKEN_ERROR:
      return {
        ...state,
        validateMissingDocumentsTokenLoading: false,
        validateMissingDocumentsTokenError: action.error,
        validateMissingDocumentsTokenSuccess: false,
      };

    case SET_USER_DOCUMENT:
      return {
        ...state,
        userDocument: action.document,
      };

    case AUTH_RESET:
      return initialState;

    default:
      return state;
  }
};
