import Api from '../../Helpers/Api';

import {
  ORDER_ISSUERS_FETCH_INIT,
  ORDER_ISSUERS_FETCH_SUCCESS,
  ORDER_ISSUERS_FETCH_ERROR,
  ORDER_ISSUERS_POST_INIT,
  ORDER_ISSUERS_POST_SUCCESS,
  ORDER_ISSUERS_POST_ERROR,
  ORDER_ISSUERS_RESET,
} from './constants';
import { normalizeOrderIssuers } from './normalizer';
import { OrderIssuersAction, OrderIssuer } from './types';

export const fetchOrderIssuersInit = (
  clientId: string | number
): OrderIssuersAction => ({
  type: ORDER_ISSUERS_FETCH_INIT,
  clientId,
});

export const fetchOrderIssuersSuccess = (
  orderIssuers: OrderIssuer[]
): OrderIssuersAction => ({
  type: ORDER_ISSUERS_FETCH_SUCCESS,
  orderIssuers,
});

export const fetchOrderIssuersError = (error: Error): OrderIssuersAction => ({
  type: ORDER_ISSUERS_FETCH_ERROR,
  error,
});

export const fetchOrderIssuers = (
  clientId: string | number
): Promise<OrderIssuer[]> => {
  return Api<OrderIssuer[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/order-issuer/${clientId}`,
    service: 'terra.register',
  }).then(normalizeOrderIssuers);
};

export const postOrderIssuersInit = (
  clientId: string | number,
  orderIssuers: OrderIssuer[]
): OrderIssuersAction => ({
  type: ORDER_ISSUERS_POST_INIT,
  clientId,
  orderIssuers,
});

export const postOrderIssuersSuccess = (): OrderIssuersAction => ({
  type: ORDER_ISSUERS_POST_SUCCESS,
});

export const postOrderIssuersError = (error: Error): OrderIssuersAction => ({
  type: ORDER_ISSUERS_POST_ERROR,
  error,
});

export const postOrderIssuers = (
  clientId: string | number,
  orderIssuers: OrderIssuer[]
): Promise<OrderIssuer[]> => {
  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/order-issuer/`,
    body: {
      ClientId: clientId,
      Issuers: orderIssuers.map((orderIssuer) => ({
        ...orderIssuer,
        ClientId: clientId,
      })),
    },
    service: 'terra.register',
  });
};

export const resetOrderIssuers = (): OrderIssuersAction => ({
  type: ORDER_ISSUERS_RESET,
});
