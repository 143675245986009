import { createStore, compose, applyMiddleware, StoreEnhancer } from 'redux';
import {
  install,
  combineReducers,
  StoreCreator,
  Loop,
  LiftedLoopReducer,
  CmdType,
} from 'redux-loop';
import persistState from 'redux-sessionstorage';
import { devToolsEnhancer } from 'redux-devtools-extension';

import { notificationsReducer } from './Notifications/reducer';
import { addressReducer } from './Address/reducer';
import { customerReducer } from './Customer/reducer';
import { passwordReducer } from './Password/reducer';
import { authReducer } from './Auth/reducer';
import { signupReducer } from './Signup/reducer';
import { stepsReducer } from './Steps/reducer';
import { agreementReducer } from './Agreement/reducer';
import { professionalReducer } from './ProfessionalInvestor/reducer';
import { suitabilityReducer } from './Suitability/reducer';
import { uploadDocumentsReducer } from './UploadDocuments/reducer';
import { signatureAndTermsReducer } from './SignatureAndTerms/reducer';
import { banksReducer } from './Banks/reducer';
import { patrimonyReducer } from './Patrimony/reducer';
import { clientReducer } from './Client/reducer';
import { complementaryDataReducer } from './ComplementaryData/reducer';
import { declarationDataReducer } from './DeclarationData/reducer';
import { knowledgeSourcesReducer } from './KnowledgeSources/reducer';
import { representativesReducer } from './Representatives/reducer';
import { orderIssuersReducer } from './OrderIssuers/reducer';
import { administratorsReducer } from './Administrators/reducer';
import { controllersReducer } from './Controllers/reducer';
import { procuratorsReducer } from './Procurators/reducer';
import { assetManagersReducer } from './AssetManager/reducer';
import { missingDocumentsReducer } from './MissingDocuments/reducer';
import { combineReducers as commonCombineReducers } from './Common';
import { partnerReducer } from './Partner/reducer';
import { PersonalDataReducer } from './PersonData';
import { CompanyDataReducer } from './CompanyData';

const enhanceStoreCreate = createStore as StoreCreator;
const commonReducers = combineReducers({
  ...commonCombineReducers(),
});

const reducers = {
  signupState: signupReducer,
  authState: authReducer,
  customerState: customerReducer,
  passwordState: passwordReducer,
  notificationsState: notificationsReducer,
  stepsState: stepsReducer,
  professionalState: professionalReducer,
  agreementState: agreementReducer,
  suitabilityState: suitabilityReducer,
  uploadDocumentsState: uploadDocumentsReducer,
  signatureAndTermsState: signatureAndTermsReducer,
  addressState: addressReducer,
  banksState: banksReducer,
  patrimonyState: patrimonyReducer,
  clientState: clientReducer,
  complementaryDataState: complementaryDataReducer,
  declarationDataState: declarationDataReducer,
  knowledgeSourcesState: knowledgeSourcesReducer,
  representativesState: representativesReducer,
  orderIssuersState: orderIssuersReducer,
  administratorsState: administratorsReducer,
  controllersState: controllersReducer,
  procuratorsState: procuratorsReducer,
  assetManagersState: assetManagersReducer,
  missingDocumentsState: missingDocumentsReducer,
  partnerState: partnerReducer,
  commonState: commonReducers,
  personalDataState: PersonalDataReducer,
  companyDataState: CompanyDataReducer,
};

type TypeWithGeneric<T> = T[];
type ExtractGeneric<Type> = Type extends TypeWithGeneric<infer X> ? X : never;

export const rootReducer = combineReducers(reducers);
export type RootReducer = typeof reducers;

export type RootState = {
  [K in keyof RootReducer]: RootReducer[K] extends LiftedLoopReducer<any>
    ? Exclude<ExtractGeneric<ReturnType<RootReducer[K]>>, CmdType>
    : Exclude<ReturnType<RootReducer[K]>, Loop<any>>;
};

type EnhancedStore = StoreEnhancer<RootState, Record<string, unknown>>;

const stateSlicerPersistor = () => (state: RootState) => ({
  authState: state.authState,
  customerState: {
    personType: state.customerState.personType,
    channelId: state.customerState.channelId,
  },
  stepsState: { currentStep: state.stepsState.currentStep },
});

let composedEnhancers: EnhancedStore;

if (process.env.NODE_ENV === 'production') {
  composedEnhancers = compose(
    install(),
    persistState(null, { slicer: stateSlicerPersistor })
  );
} else {
  composedEnhancers = compose(
    applyMiddleware() as EnhancedStore,
    install(),
    persistState(null, { slicer: stateSlicerPersistor }),
    devToolsEnhancer({ trace: true })
  );
}

export const store = enhanceStoreCreate(
  rootReducer,
  undefined,
  composedEnhancers
);

export default store;
