import { Representative } from './types';

export const normalizeRepresentatives = (representatives): Representative[] =>
  representatives.length > 0
    ? representatives.map((representative) => ({
        ...representative,
        DocumentType:
          representative.CpfCnpj?.trim().length === 14 ? 'CPF' : 'CNPJ',
      }))
    : [
        {
          Name: '',
          DocumentType: '',
          CpfCnpj: '',
          Category: '',
          Percentage: '',
        },
      ];
