import Api from '../../Helpers/Api';

import {
  ASSET_MANAGERS_FETCH_INIT,
  ASSET_MANAGERS_FETCH_SUCCESS,
  ASSET_MANAGERS_FETCH_ERROR,
  ASSET_MANAGERS_POST_INIT,
  ASSET_MANAGERS_POST_SUCCESS,
  ASSET_MANAGERS_POST_ERROR,
  ASSET_MANAGERS_RESET,
  ASSET_MANAGERS_FETCH_STATES_INIT,
  ASSET_MANAGERS_FETCH_CITIES_INIT,
  ASSET_MANAGERS_REMOVE_STATES_AND_CITIES,
  ASSET_MANAGERS_FETCH_STATES_SUCCESS,
  ASSET_MANAGERS_FETCH_STATES_ERROR,
  ASSET_MANAGERS_FETCH_CITIES_SUCCESS,
  ASSET_MANAGERS_FETCH_CITIES_ERROR,
} from './constants';
import {
  AssetManagersAction,
  AssetManager,
  AssetManagerNormalized,
} from './types';
import { normalizeAssetManagers } from './normalizer';
import { normalizeOptions } from '../Common/normalizers';

export const fetchAssetManagersInit = (
  clientId: string | number
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_INIT,
  clientId,
});

export const fetchAssetManagersSuccess = (
  assetManagers: AssetManager[]
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_SUCCESS,
  assetManagers,
});

export const fetchAssetManagersError = (error: Error): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_ERROR,
  error,
});

export const fetchAssetManagers = (
  clientId: string | number
): Promise<AssetManagerNormalized[]> => {
  return Api<AssetManager[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/asset-manager/${clientId}`,
    service: 'terra.register',
  }).then(normalizeAssetManagers);
};

export const postAssetManagersInit = (
  clientId: string | number,
  assetManagers: AssetManagerNormalized[]
): AssetManagersAction => ({
  type: ASSET_MANAGERS_POST_INIT,
  clientId,
  assetManagers,
});

export const postAssetManagersSuccess = (): AssetManagersAction => ({
  type: ASSET_MANAGERS_POST_SUCCESS,
});

export const postAssetManagersError = (error: Error): AssetManagersAction => ({
  type: ASSET_MANAGERS_POST_ERROR,
  error,
});

export const postAssetManagers = (
  clientId: string | number,
  assetManagers: AssetManagerNormalized[]
): Promise<AssetManagerNormalized[]> => {
  const assetManagerList = assetManagers.map((assetManager) => {
    const denormalizedAssetManager = { ...assetManager };

    delete denormalizedAssetManager.MobilePhoneNumber;

    return {
      ...denormalizedAssetManager,
      CustomerId: clientId,
      DDDMobilePhone: assetManager.MobilePhoneNumber?.substring(0, 2),
      MobilePhone: assetManager.MobilePhoneNumber?.substring(2),
    };
  });

  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/asset-manager/`,
    body: {
      ClientId: clientId,
      AssetManagers: assetManagerList,
    },
    service: 'terra.register',
  });
};

export const resetAssetManagers = (): AssetManagersAction => ({
  type: ASSET_MANAGERS_RESET,
});

export const fetchAssetManagerStatesInit = (
  assetManagerIndex: number
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_STATES_INIT,
  index: assetManagerIndex,
});

export const fetchAssetManagerStatesSuccess = (
  index: number,
  states: any[]
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_STATES_SUCCESS,
  index,
  states,
});

export const fetchAssetManagerStatesError = (
  index: number,
  error: Error
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_STATES_ERROR,
  index,
  error,
});

export const fetchAssetManagerStates = async (
  index: number,
  dispatch: any
): Promise<any> => {
  try {
    const states = await Api({
      method: 'GET',
      authenticate: false,
      url: '/api/common/states/BRA',
      service: 'terra.register',
    });

    return dispatch(
      fetchAssetManagerStatesSuccess(index, normalizeOptions(states))
    );
  } catch (error) {
    return dispatch(fetchAssetManagerStatesError(index, error));
  }
};

export const fetchAssetManagerCitiesInit = (
  assetManagerIndex: number,
  assetManagerStateId: number
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_CITIES_INIT,
  index: assetManagerIndex,
  stateId: assetManagerStateId,
});

export const fetchAssetManagerCitiesSuccess = (
  index: number,
  cities: any[]
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_CITIES_SUCCESS,
  index,
  cities,
});

export const fetchAssetManagerCitiesError = (
  index: number,
  error: Error
): AssetManagersAction => ({
  type: ASSET_MANAGERS_FETCH_CITIES_ERROR,
  index,
  error,
});

export const fetchAssetManagerCities = async (
  index: number,
  stateId: number,
  dispatch: any
): Promise<any> => {
  try {
    const cities = await Api({
      method: 'GET',
      authenticate: false,
      url: `/api/common/cities/${stateId}`,
      service: 'terra.register',
    });

    return dispatch(
      fetchAssetManagerCitiesSuccess(index, normalizeOptions(cities))
    );
  } catch (error) {
    return dispatch(fetchAssetManagerCitiesError(index, error));
  }
};

export const removeAssetManagerStatesAndCitiesAt = (
  assetManagerIndex: number
): AssetManagersAction => ({
  type: ASSET_MANAGERS_REMOVE_STATES_AND_CITIES,
  index: assetManagerIndex,
});
