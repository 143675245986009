import { CompanyDataAction, FetchCompanyDataResponse } from './types';
import {
  COMPANY_DATA_FETCH_INIT,
  COMPANY_DATA_FETCH_SUCCESS,
  COMPANY_DATA_FETCH_ERROR,
  COMPANY_DATA_POST_INIT,
  COMPANY_DATA_POST_SUCCESS,
  COMPANY_DATA_POST_ERROR,
  COMPANY_DATA_RESET,
} from './constants';
import Api from '../../Helpers/Api';
import { normalizeCompanyData } from './normalizer';

export const fetchCompanyDataInit = (): CompanyDataAction => ({
  type: COMPANY_DATA_FETCH_INIT,
});

export const fetchCompanyDataSuccess = (
  companyData: FetchCompanyDataResponse
): CompanyDataAction => ({
  type: COMPANY_DATA_FETCH_SUCCESS,
  companyData,
});

export const fetchCompanyDataError = (error: Error): CompanyDataAction => ({
  type: COMPANY_DATA_FETCH_ERROR,
  error,
});

export const fetchCompanyData = (): Promise<FetchCompanyDataResponse> => {
  return Api<FetchCompanyDataResponse>({
    service: 'terra.client',
    url: `/api/legal-person/company-data`,
    method: 'GET',
    authenticate: true,
  }).then(normalizeCompanyData);
};

export const postCompanyDataInit = (
  companyData: FetchCompanyDataResponse
): CompanyDataAction => ({
  type: COMPANY_DATA_POST_INIT,
  companyData,
});

export const postCompanyDataSuccess = (): CompanyDataAction => ({
  type: COMPANY_DATA_POST_SUCCESS,
});

export const postCompanyDataError = (error: Error): CompanyDataAction => ({
  type: COMPANY_DATA_POST_ERROR,
  error,
});

export const postCompanyData = (
  companyData: FetchCompanyDataResponse
): Promise<void> => {
  return Api<void>({
    service: 'terra.client',
    url: `/api/legal-person/company-data`,
    method: 'PUT',
    authenticate: true,
    body: {
      ...companyData,
    },
  });
};

export const resetCompanyData = (): CompanyDataAction => ({
  type: COMPANY_DATA_RESET,
});
