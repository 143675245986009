import { Cmd, loop, Loop, LoopReducer } from 'redux-loop';

import {
  SUITABILITY_FETCH_INIT,
  SUITABILITY_FETCH_SUCCESS,
  SUITABILITY_FETCH_ERROR,
  SUITABILITY_POST_INIT,
  SUITABILITY_POST_SUCCESS,
  SUITABILITY_POST_ERROR,
  SUITABILITY_RESET,
} from './constants';
import { SuitabilityState, SuitabilityAction } from './types';
import {
  fetchSuitability,
  fetchSuitabilitySuccess,
  fetchSuitabilityError,
  postSuitability,
  postSuitabilitySuccess,
  postSuitabilityError,
} from './actions';

const initialState: SuitabilityState = {
  fetchLoading: false,
  fetchSuccess: undefined,
  fetchError: undefined,
  questions: [],
  questionCount: 0,
  suitability: undefined,
  formQuestions: undefined,
  postLoading: false,
  postSuccess: undefined,
  postError: undefined,
};

export const suitabilityReducer: LoopReducer<
  SuitabilityState,
  SuitabilityAction
> = (
  state = initialState,
  action: SuitabilityAction
): Loop<SuitabilityState> | SuitabilityState => {
  switch (action.type) {
    case SUITABILITY_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
          fetchSuccess: undefined,
          fetchError: undefined,
        },
        Cmd.run(fetchSuitability, {
          successActionCreator: fetchSuitabilitySuccess,
          failActionCreator: fetchSuitabilityError,
          args: [action.clientId],
        })
      );

    case SUITABILITY_FETCH_SUCCESS:
      return {
        ...state,
        questions: action.questions,
        questionCount: action.questionCount,
        suitability: action.payload,
        formQuestions: action.formQuestions,
        fetchLoading: false,
        fetchSuccess: action.payload,
        fetchError: undefined,
      };

    case SUITABILITY_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchSuccess: undefined,
        fetchError: action.error,
      };

    case SUITABILITY_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
          postSuccess: undefined,
          postError: undefined,
        },
        Cmd.run(postSuitability, {
          successActionCreator: postSuitabilitySuccess,
          failActionCreator: postSuitabilityError,
          args: [
            action.values,
            action.clientId,
            action.userId,
            action.suitabilityId,
          ],
        })
      );

    case SUITABILITY_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case SUITABILITY_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: undefined,
      };

    case SUITABILITY_RESET:
      return initialState;

    default:
      return state;
  }
};
