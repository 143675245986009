import Api from '../../Helpers/Api';

import {
  PROCURATORS_FETCH_INIT,
  PROCURATORS_FETCH_SUCCESS,
  PROCURATORS_FETCH_ERROR,
  PROCURATORS_POST_INIT,
  PROCURATORS_POST_SUCCESS,
  PROCURATORS_POST_ERROR,
  PROCURATORS_RESET,
  PROCURATORS_FETCH_STATES_INIT,
  PROCURATORS_FETCH_CITIES_INIT,
  PROCURATORS_REMOVE_STATES_AND_CITIES,
  PROCURATORS_FETCH_STATES_SUCCESS,
  PROCURATORS_FETCH_STATES_ERROR,
  PROCURATORS_FETCH_CITIES_SUCCESS,
  PROCURATORS_FETCH_CITIES_ERROR,
} from './constants';
import { ProcuratorsAction, Procurator, ProcuratorNormalized } from './types';
import { normalizeProcurators } from './normalizer';
import { normalizeOptions } from '../Common/normalizers';

export const fetchProcuratorsInit = (
  clientId: string | number
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_INIT,
  clientId,
});

export const fetchProcuratorsSuccess = (
  procurators: ProcuratorNormalized[]
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_SUCCESS,
  procurators,
});

export const fetchProcuratorsError = (error: Error): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_ERROR,
  error,
});

export const fetchProcurators = (
  clientId: string | number,
  getState: any
): Promise<ProcuratorNormalized[]> => {
  return Api<Procurator[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/procurator/${clientId}`,
    service: 'terra.register',
  }).then((procurators) =>
    normalizeProcurators(procurators, getState().customerState.personType)
  );
};

export const postProcuratorsInit = (
  clientId: string | number,
  procurators: ProcuratorNormalized[]
): ProcuratorsAction => ({
  type: PROCURATORS_POST_INIT,
  clientId,
  procurators,
});

export const postProcuratorsSuccess = (): ProcuratorsAction => ({
  type: PROCURATORS_POST_SUCCESS,
});

export const postProcuratorsError = (error: Error): ProcuratorsAction => ({
  type: PROCURATORS_POST_ERROR,
  error,
});

export const postProcurators = (
  clientId: string | number,
  procurators: ProcuratorNormalized[]
): Promise<ProcuratorNormalized[]> => {
  const procuratorList = procurators.map((procurator) => {
    const denormalizedProcurator = { ...procurator };

    delete denormalizedProcurator.MobilePhoneNumber;

    return {
      ...denormalizedProcurator,
      CustomerId: clientId,
      DDDMobilePhone: procurator.MobilePhoneNumber?.substring(0, 2),
      MobilePhone: procurator.MobilePhoneNumber?.substring(2),
    };
  });

  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/procurator/`,
    body: {
      ClientId: clientId,
      Procurators: procuratorList,
    },
    service: 'terra.register',
  });
};

export const resetProcurators = (): ProcuratorsAction => ({
  type: PROCURATORS_RESET,
});

export const fetchProcuratorStatesInit = (
  procuratorIndex: number
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_STATES_INIT,
  index: procuratorIndex,
});

export const fetchProcuratorStatesSuccess = (
  index: number,
  states: any[]
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_STATES_SUCCESS,
  index,
  states,
});

export const fetchProcuratorStatesError = (
  index: number,
  error: Error
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_STATES_ERROR,
  index,
  error,
});

export const fetchProcuratorStates = async (
  index: number,
  dispatch: any
): Promise<any> => {
  try {
    const states = await Api({
      method: 'GET',
      authenticate: false,
      url: '/api/common/states/BRA',
      service: 'terra.register',
    });

    return dispatch(
      fetchProcuratorStatesSuccess(index, normalizeOptions(states))
    );
  } catch (error) {
    return dispatch(fetchProcuratorStatesError(index, error));
  }
};

export const fetchProcuratorCitiesInit = (
  procuratorIndex: number,
  procuratorStateId: number
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_CITIES_INIT,
  index: procuratorIndex,
  stateId: procuratorStateId,
});

export const fetchProcuratorCitiesSuccess = (
  index: number,
  cities: any[]
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_CITIES_SUCCESS,
  index,
  cities,
});

export const fetchProcuratorCitiesError = (
  index: number,
  error: Error
): ProcuratorsAction => ({
  type: PROCURATORS_FETCH_CITIES_ERROR,
  index,
  error,
});

export const fetchProcuratorCities = async (
  index: number,
  stateId: number,
  dispatch: any
): Promise<any> => {
  try {
    const cities = await Api({
      method: 'GET',
      authenticate: false,
      url: `/api/common/cities/${stateId}`,
      service: 'terra.register',
    });

    return dispatch(
      fetchProcuratorCitiesSuccess(index, normalizeOptions(cities))
    );
  } catch (error) {
    return dispatch(fetchProcuratorCitiesError(index, error));
  }
};

export const removeProcuratorStatesAndCitiesAt = (
  procuratorIndex: number
): ProcuratorsAction => ({
  type: PROCURATORS_REMOVE_STATES_AND_CITIES,
  index: procuratorIndex,
});
