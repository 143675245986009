import Api from '../../Helpers/Api';

import {
  ADMINISTRATORS_FETCH_INIT,
  ADMINISTRATORS_FETCH_SUCCESS,
  ADMINISTRATORS_FETCH_ERROR,
  ADMINISTRATORS_POST_INIT,
  ADMINISTRATORS_POST_SUCCESS,
  ADMINISTRATORS_POST_ERROR,
  ADMINISTRATORS_RESET,
} from './constants';
import {
  AdministratorsAction,
  Administrator,
  AdministratorNormalized,
} from './types';
import { normalizeAdministrators } from './normalizer';

export const fetchAdministratorsInit = (
  clientId: string | number
): AdministratorsAction => ({
  type: ADMINISTRATORS_FETCH_INIT,
  clientId,
});

export const fetchAdministratorsSuccess = (
  administrators: Administrator[]
): AdministratorsAction => ({
  type: ADMINISTRATORS_FETCH_SUCCESS,
  administrators,
});

export const fetchAdministratorsError = (
  error: Error
): AdministratorsAction => ({
  type: ADMINISTRATORS_FETCH_ERROR,
  error,
});

export const fetchAdministrators = (
  clientId: string | number
): Promise<Administrator[]> => {
  return Api<Administrator[]>({
    method: 'GET',
    authenticate: true,
    url: `/api/administrator/${clientId}`,
    service: 'terra.register',
  }).then(normalizeAdministrators);
};

export const postAdministratorsInit = (
  clientId: string | number,
  administrators: AdministratorNormalized[]
): AdministratorsAction => ({
  type: ADMINISTRATORS_POST_INIT,
  clientId,
  administrators,
});

export const postAdministratorsSuccess = (): AdministratorsAction => ({
  type: ADMINISTRATORS_POST_SUCCESS,
});

export const postAdministratorsError = (
  error: Error
): AdministratorsAction => ({
  type: ADMINISTRATORS_POST_ERROR,
  error,
});

export const postAdministrators = (
  clientId: string | number,
  administrators: Administrator[]
): Promise<AdministratorNormalized[]> => {
  return Api({
    method: 'PATCH',
    authenticate: true,
    url: `/api/administrator/`,
    body: {
      ClientId: clientId,
      Administrators: administrators.map((administrator) => ({
        ...administrator,
        ClientId: clientId,
      })),
    },
    service: 'terra.register',
  });
};

export const resetAdministrators = (): AdministratorsAction => ({
  type: ADMINISTRATORS_RESET,
});
