import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { AddressState, AddressAction } from './types';
import {
  ADDRESS_FETCH_INIT,
  ADDRESS_FETCH_ERROR,
  ADDRESS_FETCH_SUCCESS,
  ADDRESS_POST_INIT,
  ADDRESS_POST_ERROR,
  ADDRESS_POST_SUCCESS,
  ADDRESS_RESET,
} from './constants';

import {
  fetchAddress,
  fetchAddressSuccess,
  fetchAddressError,
  postAddress,
  postAddressSuccess,
  postAddressError,
} from './actions';

const initialState: AddressState = {
  address: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const addressReducer: LoopReducer<AddressState, AddressAction> = (
  state = initialState,
  action: AddressAction
): Loop<AddressState> | AddressState => {
  switch (action.type) {
    case ADDRESS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchAddress, {
          successActionCreator: fetchAddressSuccess,
          failActionCreator: fetchAddressError,
          args: [action.clientId],
        })
      );

    case ADDRESS_FETCH_ERROR:
      return {
        ...state,
        address: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case ADDRESS_FETCH_SUCCESS:
      return {
        ...state,
        address: action.address,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case ADDRESS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postAddress, {
          successActionCreator: postAddressSuccess,
          failActionCreator: postAddressError,
          args: [action.clientId, action.address],
        })
      );

    case ADDRESS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case ADDRESS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case ADDRESS_RESET:
      return initialState;

    default:
      return state;
  }
};
