export const COMPLEMENTARY_DATA_FETCH_INIT = 'COMPLEMENTARY_DATA_FETCH_INIT';
export const COMPLEMENTARY_DATA_FETCH_SUCCESS =
  'COMPLEMENTARY_DATA_FETCH_SUCCESS';
export const COMPLEMENTARY_DATA_FETCH_ERROR = 'COMPLEMENTARY_DATA_FETCH_ERROR';

export const COMPLEMENTARY_DATA_POST_INIT = 'COMPLEMENTARY_DATA_POST_INIT';
export const COMPLEMENTARY_DATA_POST_SUCCESS =
  'COMPLEMENTARY_DATA_POST_SUCCESS';
export const COMPLEMENTARY_DATA_POST_ERROR = 'COMPLEMENTARY_DATA_POST_ERROR';

export const COMPLEMENTARY_DATA_RESET = 'COMPLEMENTARY_DATA_RESET';
