import { Loop, LoopReducer } from 'redux-loop';

import { NOTIFICATION_POST, NOTIFICATION_CLEAR } from './constants';
import { NotificationsState, NotificationAction } from './types';

export const initialState: NotificationsState = {
  message: null,
  props: null,
};

export const notificationsReducer: LoopReducer<
  NotificationsState,
  NotificationAction
> = (
  state = initialState,
  action: NotificationAction
): Loop<NotificationsState> | NotificationsState => {
  switch (action.type) {
    case NOTIFICATION_POST:
      return {
        ...state,
        message: action.message,
        props: action.props,
      };

    case NOTIFICATION_CLEAR:
      return {
        ...state,
        message: null,
        props: null,
      };

    default:
      return state;
  }
};
