import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { AgreementState, AgreementAction } from './types';
import {
  AGREEMENT_QUALIFIED_INVESTOR_FILE_INIT,
  AGREEMENT_QUALIFIED_INVESTOR_FILE_ERROR,
  AGREEMENT_QUALIFIED_INVESTOR_FILE_SUCCESS,
} from './constants';

import {
  fetchQualifiedInvestorAgreementFile,
  fetchQualifiedInvestorAgreementFileSuccess,
  fetchQualifiedInvestorAgreementFileError,
} from './actions';

const initialState: AgreementState = {
  qualifiedInvestorFile: null,
  qualifiedInvestorFileLoading: false,
  qualifiedInvestorFileFetchError: undefined,
  qualifiedInvestorFileFetchSuccess: undefined,
};

export const agreementReducer: LoopReducer<AgreementState, AgreementAction> = (
  state = initialState,
  action: AgreementAction
): Loop<AgreementState> | AgreementState => {
  switch (action.type) {
    case AGREEMENT_QUALIFIED_INVESTOR_FILE_INIT:
      return loop(
        {
          ...state,
          qualifiedInvestorFile: null,
          qualifiedInvestorFileLoading: true,
          qualifiedInvestorFileFetchError: undefined,
          qualifiedInvestorFileFetchSuccess: undefined,
        },
        Cmd.run(fetchQualifiedInvestorAgreementFile, {
          successActionCreator: fetchQualifiedInvestorAgreementFileSuccess,
          failActionCreator: fetchQualifiedInvestorAgreementFileError,
        })
      );

    case AGREEMENT_QUALIFIED_INVESTOR_FILE_ERROR:
      return {
        ...state,
        qualifiedInvestorFile: null,
        qualifiedInvestorFileLoading: false,
        qualifiedInvestorFileFetchError: action.error,
        qualifiedInvestorFileFetchSuccess: undefined,
      };

    case AGREEMENT_QUALIFIED_INVESTOR_FILE_SUCCESS:
      return {
        ...state,
        qualifiedInvestorFile: action.payload,
        qualifiedInvestorFileLoading: false,
        qualifiedInvestorFileFetchError: undefined,
        qualifiedInvestorFileFetchSuccess: true,
      };

    default:
      return state;
  }
};
