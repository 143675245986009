import Api from '../../Helpers/Api';

import { Actions } from './constants';
import { PartnerAction, PartnerResponse, PartnerNormalized } from './types';
import { normalizePartner } from './normalizer';

export const fetchPartnerInit = (): PartnerAction => ({
  type: Actions.PARTNER_FETCH_INIT,
});

export const fetchPartnerSuccess = (
  partner: PartnerNormalized
): PartnerAction => ({
  type: Actions.PARTNER_FETCH_SUCCESS,
  partner,
});

export const fetchPartnerError = (): PartnerAction => ({
  type: Actions.PARTNER_FETCH_ERROR,
});

export const fetchPartner = (): Promise<PartnerNormalized> => {
  return Api<PartnerResponse>({
    service: 'terra.whitelabel.partner',
    url: `/api/partner/url`,
    method: 'POST',
    body: {
      Url: window.location.hostname,
    },
    authenticate: false,
  }).then((rawPartners) => normalizePartner(rawPartners));
};

export const setFirstContactUrl = (url: string): PartnerAction => ({
  type: Actions.SET_FIRST_CONTACT_URL,
  url,
});
