import moment from 'moment';

import {
  UPLOAD_DOCUMENTS_FETCH_INIT,
  UPLOAD_DOCUMENTS_FETCH_ERROR,
  UPLOAD_DOCUMENTS_FETCH_SUCCESS,
  UPLOAD_DOCUMENTS_POST_INIT,
  UPLOAD_DOCUMENTS_POST_ERROR,
  UPLOAD_DOCUMENTS_POST_SUCCESS,
  UPLOAD_DOCUMENTS_RESET,
  getSectionIdByTypePerson,
} from './constants';

import {
  RegisterDocumentResponse,
  UploadDocumentType,
  UploadDocumentTypeWithData,
  UploadDocumentsAction,
} from './types';

import Api from '../../Helpers/Api/Api';
import flattenArray from '../../Helpers/flattenArray';
import { PersonType } from '../Customer';
import { normalizeDocumentsList } from './normalizer';

export const fetchUploadDocumentsInit = (
  declarationData: any,
  orderIssuers: any,
  assetManagers: any,
  procurators: any,
  personType: PersonType
): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_FETCH_INIT,
  declarationData,
  orderIssuers,
  assetManagers,
  procurators,
  personType,
});

export const fetchUploadDocumentsSuccess = ({
  payload,
  documents,
}): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_FETCH_SUCCESS,
  payload,
  documents,
});

export const fetchUploadDocumentsError = (
  error: Error
): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_FETCH_ERROR,
  error,
});

export const fetchUploadDocuments = async (
  declarationData: any,
  orderIssuers: any,
  assetManagers: any,
  procurators: any,
  personType: PersonType
): Promise<{
  payload: RegisterDocumentResponse[];
  documents: UploadDocumentTypeWithData[];
}> => {
  const generateId = (index) => `document.${index}.${Math.random() * 16384}`;
  const { ClientId: clientId } = declarationData;

  const documentTypes = await Api<UploadDocumentType[]>({
    method: 'GET',
    url: `/api/common/receipt/${personType}`,
    authenticate: true,
    service: 'terra.register',
  }).then(normalizeDocumentsList);

  const documents = await Api<RegisterDocumentResponse[]>({
    method: 'GET',
    url: `/api/document/client/${clientId}`,
    authenticate: true,
    service: 'terra.register',
  });

  const lastVersionDocuments: RegisterDocumentResponse[] = [];

  documents.forEach((document) => {
    const exists = lastVersionDocuments.findIndex(
      (doc) =>
        doc.ReceiptTypeId === document.ReceiptTypeId &&
        doc.CpfCnpj === document.CpfCnpj
    );

    if (exists !== -1) {
      lastVersionDocuments[exists] = document;
    } else {
      lastVersionDocuments.push(document);
    }
  });

  const getBlob = async (receiptId) => {
    if (!receiptId) {
      return undefined;
    }
    return {};
  };

  const documentMapper = async (
    mappedArray: any[],
    documentType: UploadDocumentType,
    index: number,
    mappedItemUniqProp,
    mappedItemNameProp
  ) => {
    const mappedDocuments = mappedArray.map(
      async (mappedItem, mappedItemIndex) => {
        let newDescription;
        const document = lastVersionDocuments.find(
          (doc) =>
            doc.ReceiptTypeId === documentType.Value &&
            doc.CpfCnpj === mappedItem[mappedItemUniqProp]
        );

        switch (documentType.Value) {
          case 10:
            newDescription = `Cópia do RG/CPF do ${mappedItem[mappedItemNameProp]}`;
            break;

          case 11:
            newDescription = `Comprovante de residência do ${mappedItem[mappedItemNameProp]}`;
            break;

          case 17:
            newDescription = `Procuração para ${mappedItem[mappedItemNameProp]}`;
            break;

          case 20:
            newDescription = `Comprovante de Identidade do ${mappedItem[mappedItemNameProp]}`;
            break;

          case 26:
          case 27:
            newDescription = `Contrato do Administrador de Carteira ${mappedItem[mappedItemNameProp]}`;
            break;

          default:
            newDescription = mappedItem.Name;
        }

        const patchedDocumentType = {
          ...documentType,
          Description: newDescription,
        };

        const mappedDocument: any = {
          ...patchedDocumentType,
          CpfCnpj: mappedItem[mappedItemUniqProp],
          Document: document,
          Blob: await getBlob(document?.ReceiptId),
          Id: generateId(`${index}.${mappedItemIndex}`),
        };

        return mappedDocument;
      }
    );

    const mapperResult = await Promise.all(mappedDocuments);
    return mapperResult;
  };

  const getDocumentDescription = async (documentType) => {
    return documentType.DocumentBaseDescription || documentType.Description;
  };

  const documentBlobRequests: Promise<
    UploadDocumentTypeWithData | UploadDocumentTypeWithData[]
  >[] = documentTypes.map(
    (documentType, index) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (accept, _reject) => {
        if ([10, 11].indexOf(documentType.Value) !== -1) {
          if (orderIssuers.length) {
            accept(
              documentMapper(orderIssuers, documentType, index, 'Cpf', 'Name')
            );
          } else if (
            declarationData.AuthorizesOrdersByProcurator &&
            documentType.Value === 10
          ) {
            // ignore procurator documents
            const document = lastVersionDocuments.find(
              (doc) => doc.ReceiptTypeId === documentType.Value
            );
            accept({
              ...documentType,
              Document: document,
              Blob: await getBlob(document?.ReceiptId),
              Id: generateId(index),
            });
          } else {
            accept([]);
          }
        } else if ([26, 27].indexOf(documentType.Value) !== -1) {
          if (declarationData.AuthorizesOrdersByAdministrator) {
            accept(
              documentMapper(
                assetManagers,
                documentType,
                index,
                'AssetManagerCpfCnpj',
                'AssetManagerName'
              )
            );
          } else {
            accept([]);
          }
        } else if (
          [17, 20, 21].indexOf(documentType.Value) !== -1 &&
          !declarationData.AuthorizesOrdersByProcurator
        ) {
          accept([]);
        } else if (
          [17, 20].indexOf(documentType.Value) !== -1 &&
          declarationData.AuthorizesOrdersByProcurator
        ) {
          accept(
            documentMapper(
              procurators,
              documentType,
              index,
              'ProcuratorCpfCnpj',
              'ProcuratorName'
            )
          );
        } else if (
          [21].indexOf(documentType.Value) !== -1 &&
          declarationData.AuthorizesOrdersByProcurator
        ) {
          accept([]);
        } else if (
          [28, 29].indexOf(documentType.Value) !== -1 &&
          personType === PersonType.F
        ) {
          accept([]);
        } else {
          // ignore procurator documents
          const document = lastVersionDocuments.find(
            (doc) => doc.ReceiptTypeId === documentType.Value
          );

          accept({
            ...documentType,
            Document: document,
            Description: await getDocumentDescription(documentType),
            Blob: await getBlob(document?.ReceiptId),
            Id: generateId(index),
          });
        }
      })
  );

  const documentsWithBlob = await Promise.all(documentBlobRequests);

  return {
    documents: flattenArray(documentsWithBlob),
    payload: documents,
  };
};

export const postUploadDocumentsInit = (
  clientId: number | string,
  uploads: any[]
): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_POST_INIT,
  clientId,
  uploads,
});

export const postUploadDocumentsSuccess = (): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_POST_SUCCESS,
});

export const postUploadDocumentsError = (
  error: Error
): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_POST_ERROR,
  error,
});

export const postUploadDocuments = async (
  clientId: string | number,
  uploads: any[]
): Promise<void> => {
  const uploadList = uploads.map(async (upload) => {
    const formData = new FormData();

    formData.append('receipt_customerid', clientId.toString());
    formData.append('receipt_type', upload.document.Value.toString());
    formData.append('image0', upload.file, upload.file.name);
    formData.append(
      'receipt_validity_date',
      moment(new Date()).format('DD/MM/YYYY')
    );

    if (upload.document.CpfCnpj) {
      formData.append('receipt_document', upload.document.CpfCnpj.toString());
    }

    await Api({
      method: 'POST',
      url: '/api/document',
      service: 'terra.register',
      authenticate: true,
      body: formData,
      baseUrl: process.env.UPLOADS_URL || process.env.API_URL,
    });
  });

  await Promise.all(uploadList);
};

export const uploadDocumentsReset = (): UploadDocumentsAction => ({
  type: UPLOAD_DOCUMENTS_RESET,
});

export const documentsStepConcluded = (
  clientId: number | string,
  typePerson: PersonType
): Promise<any> => {
  const body = {
    ClientId: clientId,
    SectionId: getSectionIdByTypePerson(typePerson),
  };
  return Api({
    method: 'POST',
    url: '/api/section',
    service: 'terra.register',
    authenticate: true,
    body,
  });
};
