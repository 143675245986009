import { OrderIssuer } from './types';

export const normalizeOrderIssuers = (
  orderIssuers: OrderIssuer[]
): OrderIssuer[] =>
  orderIssuers.length > 0
    ? orderIssuers.map((orderIssuer) => ({
        ...orderIssuer,
      }))
    : [
        {
          Name: '',
          Cpf: '',
          Rg: '',
          Email: '',
        },
      ];
