export const PROCURATORS_FETCH_INIT = 'PROCURATORS_FETCH_INIT';
export const PROCURATORS_FETCH_SUCCESS = 'PROCURATORS_FETCH_SUCCESS';
export const PROCURATORS_FETCH_ERROR = 'PROCURATORS_FETCH_ERROR';

export const PROCURATORS_POST_INIT = 'PROCURATORS_POST_INIT';
export const PROCURATORS_POST_SUCCESS = 'PROCURATORS_POST_SUCCESS';
export const PROCURATORS_POST_ERROR = 'PROCURATORS_POST_ERROR';

export const PROCURATORS_RESET = 'PROCURATORS_RESET';

export const PROCURATORS_FETCH_STATES_INIT = 'PROCURATORS_FETCH_STATES_INIT';
export const PROCURATORS_FETCH_STATES_SUCCESS =
  'PROCURATORS_FETCH_STATES_SUCCESS';
export const PROCURATORS_FETCH_STATES_ERROR = 'PROCURATORS_FETCH_STATES_ERROR';

export const PROCURATORS_FETCH_CITIES_INIT = 'PROCURATORS_FETCH_CITIES_INIT';
export const PROCURATORS_FETCH_CITIES_SUCCESS =
  'PROCURATORS_FETCH_CITIES_SUCCESS';
export const PROCURATORS_FETCH_CITIES_ERROR = 'PROCURATORS_FETCH_CITIES_ERROR';

export const PROCURATORS_REMOVE_STATES_AND_CITIES =
  'PROCURATORS_REMOVE_STATES_AND_CITIES';
