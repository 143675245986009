import { DeclarationDataAction, FetchDeclarationDataResponse } from './types';
import {
  DECLARATION_DATA_FETCH_INIT,
  DECLARATION_DATA_FETCH_SUCCESS,
  DECLARATION_DATA_FETCH_ERROR,
  DECLARATION_DATA_POST_INIT,
  DECLARATION_DATA_POST_SUCCESS,
  DECLARATION_DATA_POST_ERROR,
  DECLARATION_DATA_RESET,
} from './constants';
import Api from '../../Helpers/Api';

export const fetchDeclarationDataInit = (
  clientId: string | number
): DeclarationDataAction => ({
  type: DECLARATION_DATA_FETCH_INIT,
  clientId,
});

export const fetchDeclarationDataSuccess = (
  declarationData: FetchDeclarationDataResponse
): DeclarationDataAction => ({
  type: DECLARATION_DATA_FETCH_SUCCESS,
  declarationData,
});

export const fetchDeclarationDataError = (
  error: Error
): DeclarationDataAction => ({
  type: DECLARATION_DATA_FETCH_ERROR,
  error,
});

export const fetchDeclarationData = (
  clientId: string | number
): Promise<FetchDeclarationDataResponse> => {
  return Api<FetchDeclarationDataResponse>({
    service: 'terra.register',
    url: `/api/client/declaration-data/${clientId}`,
    method: 'GET',
    authenticate: true,
  });
};

export const postDeclarationDataInit = (
  declarationData: FetchDeclarationDataResponse
): DeclarationDataAction => ({
  type: DECLARATION_DATA_POST_INIT,
  declarationData,
});

export const postDeclarationDataSuccess = (): DeclarationDataAction => ({
  type: DECLARATION_DATA_POST_SUCCESS,
});

export const postDeclarationDataError = (
  error: Error
): DeclarationDataAction => ({
  type: DECLARATION_DATA_POST_ERROR,
  error,
});

export const postDeclarationData = (
  declarationData: FetchDeclarationDataResponse
): Promise<void> => {
  return Api<void>({
    service: 'terra.register',
    url: `/api/client/declaration-data`,
    method: 'POST',
    authenticate: true,
    body: declarationData,
  });
};

export const resetDeclarationData = (): DeclarationDataAction => ({
  type: DECLARATION_DATA_RESET,
});

export const fetchTermFile = (url, callback) => {
  Api({
    service: 'terra.compliance.agreement',
    url,
    method: 'GET',
    authenticate: true,
    returnPDF: true,
  }).then((response) => {
    response.blob().then((blobResponse) => {
      const blob = new Blob([blobResponse], { type: 'application/pdf' });
      callback(URL.createObjectURL(blob));
    });
  });
};
