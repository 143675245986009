import {
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_INIT,
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_ERROR,
  AGREEMENT_PROFESSIONAL_INVESTOR_FILE_SUCCESS,
} from './constants';

import Api from '../../Helpers/Api';
import { AgreementAction } from './types';

const fetchFile = async (fileId) => {
  const response = await Api({
    service: 'terra.compliance.agreement',
    url: `/api/file/${fileId}`,
    method: 'GET',
    authenticate: true,
    returnResponse: true,
  });

  const file: Blob = new Blob([await response.blob()], {
    type: 'application/pdf',
  });
  const fileURL: string = URL.createObjectURL(file);
  return fileURL;
};

export const fetchProfessionalInvestorAgreementFileInit = (): AgreementAction => ({
  type: AGREEMENT_PROFESSIONAL_INVESTOR_FILE_INIT,
});

export const fetchProfessionalInvestorAgreementFileSuccess = (
  fileUrl: string
): AgreementAction => ({
  type: AGREEMENT_PROFESSIONAL_INVESTOR_FILE_SUCCESS,
  payload: fileUrl,
});

export const fetchProfessionalInvestorAgreementFileError = (
  error: Error
): AgreementAction => ({
  type: AGREEMENT_PROFESSIONAL_INVESTOR_FILE_ERROR,
  error,
});

export const fetchProfessionalInvestorAgreementFile = (): Promise<string> => {
  return fetchFile(34);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postSignAgreementInit = async (
  fileId: number,
  clientId: string | number,
  electronicCheck: string
) => {
  const response = await Api({
    service: 'terra.compliance.agreement',
    url: '/api/client-agreement',
    method: 'POST',
    authenticate: true,
    returnResponse: true,
    body: {
      EletronicCheck: electronicCheck,
      AgreementId: fileId,
      ClientId: clientId,
    },
  });

  return response;
};
