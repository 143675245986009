import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { ControllersState, ControllersAction } from './types';
import {
  CONTROLLERS_FETCH_INIT,
  CONTROLLERS_FETCH_ERROR,
  CONTROLLERS_FETCH_SUCCESS,
  CONTROLLERS_POST_INIT,
  CONTROLLERS_POST_ERROR,
  CONTROLLERS_POST_SUCCESS,
  CONTROLLERS_RESET,
} from './constants';

import {
  fetchControllers,
  fetchControllersSuccess,
  fetchControllersError,
  postControllers,
  postControllersSuccess,
  postControllersError,
} from './actions';

const initialState: ControllersState = {
  controllers: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const controllersReducer: LoopReducer<
  ControllersState,
  ControllersAction
> = (
  state = initialState,
  action: ControllersAction
): Loop<ControllersState> | ControllersState => {
  switch (action.type) {
    case CONTROLLERS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchControllers, {
          successActionCreator: fetchControllersSuccess,
          failActionCreator: fetchControllersError,
          args: [action.clientId],
        })
      );

    case CONTROLLERS_FETCH_ERROR:
      return {
        ...state,
        controllers: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case CONTROLLERS_FETCH_SUCCESS:
      return {
        ...state,
        controllers: action.controllers,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case CONTROLLERS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postControllers, {
          successActionCreator: postControllersSuccess,
          failActionCreator: postControllersError,
          args: [action.clientId, action.controllers],
        })
      );

    case CONTROLLERS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case CONTROLLERS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case CONTROLLERS_RESET:
      return initialState;

    default:
      return state;
  }
};
