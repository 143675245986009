import { PersonType } from '../Customer';

export const UPLOAD_DOCUMENTS_FETCH_INIT = 'UPLOAD_DOCUMENTS_FETCH_INIT';
export const UPLOAD_DOCUMENTS_FETCH_ERROR = 'UPLOAD_DOCUMENTS_FETCH_ERROR';
export const UPLOAD_DOCUMENTS_FETCH_SUCCESS = 'UPLOAD_DOCUMENTS_FETCH_SUCCESS';

export const UPLOAD_DOCUMENTS_POST_INIT = 'UPLOAD_DOCUMENTS_POST_INIT';
export const UPLOAD_DOCUMENTS_POST_ERROR = 'UPLOAD_DOCUMENTS_POST_ERROR';
export const UPLOAD_DOCUMENTS_POST_SUCCESS = 'UPLOAD_DOCUMENTS_POST_SUCCESS';

export const UPLOAD_DOCUMENTS_RESET = 'UPLOAD_DOCUMENTS_RESET';

export const getSectionIdByTypePerson = (typePerson: PersonType): number => {
  if (typePerson === PersonType.F) {
    return 8;
  }
  if (typePerson === PersonType.J) {
    return 105;
  }
  return 0;
};
