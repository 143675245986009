import { Loop, LoopReducer } from 'redux-loop';
import { StepsState, StepsAction } from './types';
import {
  STEPS_SET_STEP,
  STEPS_BACK_STEP,
  STEPS_FOWARD_STEP,
  STEPS_RESET,
} from './constants';

const initialState: StepsState = {
  currentStep: 0,
  isStepBack: false,
};

export const stepsReducer: LoopReducer<StepsState, StepsAction> = (
  state = initialState,
  action: StepsAction
): Loop<StepsState> | StepsState => {
  switch (action.type) {
    case STEPS_SET_STEP:
      return {
        ...state,
        currentStep: action.step,
        isStepBack: action.step < state.currentStep,
      };

    case STEPS_BACK_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
        isStepBack: true,
      };

    case STEPS_FOWARD_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        isStepBack: false,
      };

    case STEPS_RESET:
      return initialState;

    default:
      return state;
  }
};
