import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import {
  BANKS_FETCH_INIT,
  BANKS_FETCH_SUCCESS,
  BANKS_FETCH_ERROR,
  BANKS_POST_INIT,
  BANKS_POST_SUCCESS,
  BANKS_POST_ERROR,
  BANKS_RESET,
} from './constants';

import { BanksState, BanksAction } from './types';

import {
  fetchBanks,
  fetchBanksSuccess,
  fetchBanksError,
  postBanks,
  postBanksSuccess,
  postBanksError,
} from './actions';

const initialState: BanksState = {
  banks: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const banksReducer: LoopReducer<BanksState, BanksAction> = (
  state = initialState,
  action: BanksAction
): Loop<BanksState> | BanksState => {
  switch (action.type) {
    case BANKS_FETCH_INIT:
      return loop(
        { ...state, fetchLoading: true },
        Cmd.run(fetchBanks, {
          successActionCreator: fetchBanksSuccess,
          failActionCreator: fetchBanksError,
          args: [action.clientId],
        })
      );

    case BANKS_FETCH_SUCCESS:
      return {
        ...state,
        banks: action.banks,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case BANKS_FETCH_ERROR:
      return {
        ...state,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case BANKS_POST_INIT:
      return loop(
        { ...state, postLoading: true },
        Cmd.run(postBanks, {
          successActionCreator: postBanksSuccess,
          failActionCreator: postBanksError,
          args: [action.clientId, action.banks],
        })
      );

    case BANKS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case BANKS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case BANKS_RESET:
      return initialState;

    default:
      return state;
  }
};
