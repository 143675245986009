import { FetchPersonalDataResponse } from './types';

export const normalizePersonalData = (
  personData: FetchPersonalDataResponse
): FetchPersonalDataResponse => ({
  ...personData,
  BirthCityId: personData.BirthCityId || null,
  BirthCountryId: personData.BirthCountryId || null,
  BirthStateId: personData.BirthStateId || null,
  MaritalStatusId: personData.MaritalStatusId || null,
  NationalityId: personData.NationalityId || null,
  OccupationId: personData.OccupationId || null,
});
