import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { ClientState, ClientAction } from './types';
import {
  CLIENT_FETCH_INIT,
  CLIENT_FETCH_ERROR,
  CLIENT_FETCH_SUCCESS,
  CLIENT_POST_INIT,
  CLIENT_POST_ERROR,
  CLIENT_POST_SUCCESS,
  CLIENT_RESET,
} from './constants';

import {
  fetchClient,
  fetchClientSuccess,
  fetchClientError,
  postClient,
  postClientSuccess,
  postClientError,
} from './actions';

const initialState: ClientState = {
  client: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const clientReducer: LoopReducer<ClientState, ClientAction> = (
  state = initialState,
  action: ClientAction
): Loop<ClientState> | ClientState => {
  switch (action.type) {
    case CLIENT_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchClient, {
          successActionCreator: fetchClientSuccess,
          failActionCreator: fetchClientError,
        })
      );

    case CLIENT_FETCH_ERROR:
      return {
        ...state,
        client: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        client: action.client,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case CLIENT_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postClient, {
          successActionCreator: postClientSuccess,
          failActionCreator: postClientError,
          args: [action.client],
        })
      );

    case CLIENT_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case CLIENT_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case CLIENT_RESET:
      return initialState;

    default:
      return state;
  }
};
