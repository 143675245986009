import Api from '../../Helpers/Api';

import { PatrimonyAction, PatrimonyResponse } from './types';
import {
  PATRIMONY_FETCH_INIT,
  PATRIMONY_FETCH_SUCCESS,
  PATRIMONY_FETCH_ERROR,
  PATRIMONY_RESET,
  PATRIMONY_POST_INIT,
  PATRIMONY_POST_SUCCESS,
  PATRIMONY_POST_ERROR,
} from './constants';

export const fetchPatrimonyInit = (
  clientId: string | number
): PatrimonyAction => ({
  type: PATRIMONY_FETCH_INIT,
  clientId,
});

export const fetchPatrimonySuccess = (
  patrimony: PatrimonyResponse
): PatrimonyAction => ({
  type: PATRIMONY_FETCH_SUCCESS,
  patrimony,
});

export const fetchPatrimonyError = (error: Error): PatrimonyAction => ({
  type: PATRIMONY_FETCH_ERROR,
  error,
});

export const fetchPatrimony = (
  clientId: string | number
): Promise<PatrimonyResponse> => {
  return Api<PatrimonyResponse>({
    url: `/api/patrimony/${clientId}`,
    method: 'GET',
    service: 'terra.register',
    authenticate: true,
  });
};

export const patrimonyReset = (): PatrimonyAction => ({
  type: PATRIMONY_RESET,
});

export const postPatrimontyInit = (
  clientId: string | number,
  patrimony: PatrimonyResponse
): PatrimonyAction => ({
  type: PATRIMONY_POST_INIT,
  patrimony,
  clientId,
});

export const postPatrimonySuccess = (): PatrimonyAction => ({
  type: PATRIMONY_POST_SUCCESS,
});

export const postPatrimonyError = (error: Error): PatrimonyAction => ({
  type: PATRIMONY_POST_ERROR,
  error,
});

export const postPatrimony = (
  clientId: string | number,
  patrimony: PatrimonyResponse
): Promise<void> => {
  return Api<void>({
    url: `/api/patrimony`,
    method: patrimony.ClientId ? 'PUT' : 'POST',
    service: 'terra.register',
    authenticate: true,
    body: { ...patrimony, ClientId: clientId },
  });
};
