export const SIGNATURE_AND_TERMS_POST_INIT = 'SIGNATURE_AND_TERMS_POST_INIT';
export const SIGNATURE_AND_TERMS_POST_SUCCESS =
  'SIGNATURE_AND_TERMS_POST_SUCCESS';
export const SIGNATURE_AND_TERMS_POST_ERROR = 'SIGNATURE_AND_TERMS_POST_ERROR';

export const SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_INIT';
export const SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_SUCCESS';
export const SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_AGREEMENT_ERROR';

export const SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_INIT';

export const SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_SUCCESS';

export const SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR =
  'SIGNATURE_AND_TERMS_FETCH_EXISTING_SIGNATURE_ERROR';

export const SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT =
  'SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_INIT';
export const SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS =
  'SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_SUCCESS';
export const SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR =
  'SIGNATURE_AND_TERMS_POST_REQUEST_NEW_SIGNATURE_ERROR';

export const SIGNATURE_AND_TERMS_RESET = 'SIGNATURE_AND_TERMS_RESET';
