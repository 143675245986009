import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { ProcuratorsState, ProcuratorsAction } from './types';
import {
  PROCURATORS_FETCH_INIT,
  PROCURATORS_FETCH_ERROR,
  PROCURATORS_FETCH_SUCCESS,
  PROCURATORS_POST_INIT,
  PROCURATORS_POST_ERROR,
  PROCURATORS_POST_SUCCESS,
  PROCURATORS_RESET,
  PROCURATORS_FETCH_STATES_INIT,
  PROCURATORS_FETCH_STATES_SUCCESS,
  PROCURATORS_FETCH_STATES_ERROR,
  PROCURATORS_FETCH_CITIES_INIT,
  PROCURATORS_FETCH_CITIES_SUCCESS,
  PROCURATORS_FETCH_CITIES_ERROR,
} from './constants';

import {
  fetchProcurators,
  fetchProcuratorsSuccess,
  fetchProcuratorsError,
  postProcurators,
  postProcuratorsSuccess,
  postProcuratorsError,
  fetchProcuratorStates,
  fetchProcuratorCities,
} from './actions';

const initialState: ProcuratorsState = {
  procuratorCities: [],
  procuratorStates: [],
  procurators: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const procuratorsReducer: LoopReducer<
  ProcuratorsState,
  ProcuratorsAction
> = (
  state = initialState,
  action: ProcuratorsAction
): Loop<ProcuratorsState> | ProcuratorsState => {
  switch (action.type) {
    case PROCURATORS_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchProcurators, {
          successActionCreator: fetchProcuratorsSuccess,
          failActionCreator: fetchProcuratorsError,
          args: [action.clientId, Cmd.getState],
        })
      );

    case PROCURATORS_FETCH_ERROR:
      return {
        ...state,
        procurators: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case PROCURATORS_FETCH_SUCCESS:
      return {
        ...state,
        procurators: action.procurators,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case PROCURATORS_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postProcurators, {
          successActionCreator: postProcuratorsSuccess,
          failActionCreator: postProcuratorsError,
          args: [action.clientId, action.procurators],
        })
      );

    case PROCURATORS_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case PROCURATORS_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case PROCURATORS_FETCH_STATES_INIT:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorStatesInit = [...state.procuratorStates];

      newProcuratorStatesInit[action.index] = {
        loading: true,
        success: false,
        error: undefined,
        states: undefined,
      };

      return loop(
        {
          ...state,
          procuratorStates: newProcuratorStatesInit,
        },
        Cmd.run(fetchProcuratorStates, {
          args: [action.index, Cmd.dispatch],
        })
      );

    case PROCURATORS_FETCH_STATES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorStatesSuccess = [...state.procuratorStates];

      newProcuratorStatesSuccess[action.index] = {
        loading: false,
        success: true,
        error: undefined,
        states: action.states,
      };

      return {
        ...state,
        procuratorStates: newProcuratorStatesSuccess,
      };

    case PROCURATORS_FETCH_STATES_ERROR:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorStatesError = [...state.procuratorStates];

      newProcuratorStatesError[action.index] = {
        loading: false,
        success: false,
        error: action.error,
        states: [],
      };

      return {
        ...state,
        procuratorStates: newProcuratorStatesError,
      };

    case PROCURATORS_FETCH_CITIES_INIT:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorCitiesInit = [...state.procuratorCities];

      newProcuratorCitiesInit[action.index] = {
        loading: true,
        success: false,
        error: undefined,
        cities: undefined,
      };

      return loop(
        {
          ...state,
          procuratorCities: newProcuratorCitiesInit,
        },
        Cmd.run(fetchProcuratorCities, {
          args: [action.index, action.stateId, Cmd.dispatch],
        })
      );

    case PROCURATORS_FETCH_CITIES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorCitiesSuccess = [...state.procuratorCities];

      newProcuratorCitiesSuccess[action.index] = {
        loading: false,
        success: true,
        error: undefined,
        cities: action.cities,
      };

      return {
        ...state,
        procuratorCities: newProcuratorCitiesSuccess,
      };

    case PROCURATORS_FETCH_CITIES_ERROR:
      // eslint-disable-next-line no-case-declarations
      const newProcuratorCitiesError = [...state.procuratorCities];

      newProcuratorCitiesError[action.index] = {
        loading: false,
        success: false,
        error: action.error,
        cities: [],
      };

      return {
        ...state,
        procuratorCities: newProcuratorCitiesError,
      };

    case PROCURATORS_RESET:
      return initialState;

    default:
      return state;
  }
};
