import Api from '../../Helpers/Api';
import {
  PASSWORD_FETCH_EXISTING_INIT,
  PASSWORD_FETCH_EXISTING_SUCCESS,
  PASSWORD_FETCH_EXISTING_ERROR,
} from './constants';
import { normalizePassword } from './normalizer';
import {
  FetchExistingPasswordErrorAction,
  FetchExistingPasswordInitAction,
  FetchExistingPasswordSuccessAction,
  FetchPasswordResponseProps,
} from './types';

export const fetchExistingPasswordInit = (): FetchExistingPasswordInitAction => ({
  type: PASSWORD_FETCH_EXISTING_INIT,
});

export const fetchExistingPasswordSuccess = (
  hasPassword: boolean
): FetchExistingPasswordSuccessAction => ({
  type: PASSWORD_FETCH_EXISTING_SUCCESS,
  hasPassword,
});

export const fetchExistingPasswordError = (
  error: Error
): FetchExistingPasswordErrorAction => ({
  type: PASSWORD_FETCH_EXISTING_ERROR,
  error,
});

export const fetchExistingPassword = async (): Promise<boolean> => {
  return Api<FetchPasswordResponseProps>({
    method: 'GET',
    authenticate: true,
    url: `/api/password/verify`,
    service: 'terra.authentication',
  }).then(normalizePassword);
};

export const fetchNewPassword = (payload) => {
  return Api<Promise<any>>({
    method: 'PUT',
    authenticate: false,
    url: `/api/password/request-new`,
    service: 'terra.authentication',
    body: {
      ...payload,
    },
  });
};

export const fetchResetPassword = (payload) => {
  return Api<Promise<any>>({
    method: 'PUT',
    authenticate: false,
    url: `/api/password/reset`,
    service: 'terra.authentication',
    body: {
      ...payload,
    },
  });
};
