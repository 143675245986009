import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { DeclarationDataState, DeclarationDataAction } from './types';
import {
  DECLARATION_DATA_FETCH_INIT,
  DECLARATION_DATA_FETCH_ERROR,
  DECLARATION_DATA_FETCH_SUCCESS,
  DECLARATION_DATA_POST_INIT,
  DECLARATION_DATA_POST_ERROR,
  DECLARATION_DATA_POST_SUCCESS,
  DECLARATION_DATA_RESET,
} from './constants';

import {
  fetchDeclarationData,
  fetchDeclarationDataSuccess,
  fetchDeclarationDataError,
  postDeclarationData,
  postDeclarationDataSuccess,
  postDeclarationDataError,
} from './actions';

const initialState: DeclarationDataState = {
  declarationData: undefined,
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: undefined,
  postLoading: false,
  postSuccess: false,
  postError: undefined,
};

export const declarationDataReducer: LoopReducer<
  DeclarationDataState,
  DeclarationDataAction
> = (
  state = initialState,
  action: DeclarationDataAction
): Loop<DeclarationDataState> | DeclarationDataState => {
  switch (action.type) {
    case DECLARATION_DATA_FETCH_INIT:
      return loop(
        {
          ...state,
          fetchLoading: true,
        },
        Cmd.run(fetchDeclarationData, {
          successActionCreator: fetchDeclarationDataSuccess,
          failActionCreator: fetchDeclarationDataError,
          args: [action.clientId],
        })
      );

    case DECLARATION_DATA_FETCH_ERROR:
      return {
        ...state,
        declarationData: undefined,
        fetchLoading: false,
        fetchError: action.error,
        fetchSuccess: false,
      };

    case DECLARATION_DATA_FETCH_SUCCESS:
      return {
        ...state,
        declarationData: action.declarationData,
        fetchLoading: false,
        fetchError: undefined,
        fetchSuccess: true,
      };

    case DECLARATION_DATA_POST_INIT:
      return loop(
        {
          ...state,
          postLoading: true,
        },
        Cmd.run(postDeclarationData, {
          successActionCreator: postDeclarationDataSuccess,
          failActionCreator: postDeclarationDataError,
          args: [action.declarationData],
        })
      );

    case DECLARATION_DATA_POST_ERROR:
      return {
        ...state,
        postLoading: false,
        postError: action.error,
        postSuccess: false,
      };

    case DECLARATION_DATA_POST_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: undefined,
        postSuccess: true,
      };

    case DECLARATION_DATA_RESET:
      return initialState;

    default:
      return state;
  }
};
