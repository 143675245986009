import {
  ComplementaryDataAction,
  FetchComplementaryDataResponse,
} from './types';
import {
  COMPLEMENTARY_DATA_FETCH_INIT,
  COMPLEMENTARY_DATA_FETCH_SUCCESS,
  COMPLEMENTARY_DATA_FETCH_ERROR,
  COMPLEMENTARY_DATA_POST_INIT,
  COMPLEMENTARY_DATA_POST_SUCCESS,
  COMPLEMENTARY_DATA_POST_ERROR,
  COMPLEMENTARY_DATA_RESET,
} from './constants';
import Api from '../../Helpers/Api';
import { normalizeComplementaryData } from './normalizer';
import { cleanDocument } from '../../Helpers/Document';

export const fetchComplementaryDataInit = (): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_FETCH_INIT,
});

export const fetchComplementaryDataSuccess = (
  complementaryData: FetchComplementaryDataResponse
): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_FETCH_SUCCESS,
  complementaryData,
});

export const fetchComplementaryDataError = (
  error: Error
): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_FETCH_ERROR,
  error,
});

export const fetchComplementaryData = (): Promise<
  FetchComplementaryDataResponse
> => {
  return Api<FetchComplementaryDataResponse>({
    service: 'terra.register',
    url: `/api/client/complementary-data`,
    method: 'GET',
    authenticate: true,
  }).then(normalizeComplementaryData);
};

export const postComplementaryDataInit = (
  complementaryData: FetchComplementaryDataResponse
): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_POST_INIT,
  complementaryData,
});

export const postComplementaryDataSuccess = (): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_POST_SUCCESS,
});

export const postComplementaryDataError = (
  error: Error
): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_POST_ERROR,
  error,
});

export const postComplementaryData = (
  complementaryData: FetchComplementaryDataResponse
): Promise<void> => {
  return Api<void>({
    service: 'terra.register',
    url: `/api/client/complementary-data`,
    method: 'POST',
    authenticate: true,
    body: {
      ...complementaryData,
      CompanyCnpj: complementaryData.CompanyCnpj
        ? cleanDocument(complementaryData.CompanyCnpj)
        : undefined,
    },
  });
};

export const resetComplementaryData = (): ComplementaryDataAction => ({
  type: COMPLEMENTARY_DATA_RESET,
});
