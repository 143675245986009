import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../Redux/store';
import { StyledSpinner, StyledContainer } from './styles';

const SuspenseLoading: React.FC = () => {
  const [container] = React.useState(document.createElement('div'));
  const { theme } = useSelector((state: RootState) => state.partnerState);

  React.useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  const loadingContainer = (
    <StyledContainer>
      <StyledSpinner theme={theme} />
    </StyledContainer>
  );

  return ReactDOM.createPortal(loadingContainer, container);
};

export default SuspenseLoading;
